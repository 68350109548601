import { RootState, store } from 'store';
import * as api from './../../api/news';
import * as adsApi from './../../api/advertisement';

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

export const getNewsList = createAsyncThunk(
  'news/getNewsList',
  async ({ page, limit }: { page: number; limit: number }) => {
    return await api.getNewsList(page, limit);
  },
);
export const getPinnedPosts = createAsyncThunk(
  'news/getPinnedPosts',
  async ({ id, page }: { id: string; type: 'feed' | 'kreise'; page: number }) => {
    if (id === 'feed') id = 'news';
    return await api.getPinnedPosts(id, page);
  },
);

export const getNewsListById = createAsyncThunk(
  'news/getNewsListById',
  async ({ kreiseId, page, limit }: { kreiseId: string; page: number; limit: number }) => {
    return await api.getNewsListById(kreiseId, page);
  },
);

export const getNewsById = createAsyncThunk('news/getNewsById', async ({ postId }: { postId: string }) => {
  return await api.getNewsById(postId);
});

export const deleteNews = createAsyncThunk('news/deleteNews', async ({ postId }: { postId: string }) => {
  return await api.deleteNews(postId);
});

export const getAllAds = createAsyncThunk('news/getAllAds', async () => {
  try {
    return await adsApi.getAllAds();
  } catch (err) {
    console.log('errrrr', err);
  }
});

export const addNews = createAsyncThunk('news/addNews', async ({ postEntity }: { postEntity: any }) => {
  return await api.addNews(postEntity);
});

export const submitEditing = createAsyncThunk(
  'news/submitEditing',
  async ({ postEntity, type }: { postEntity: any; type: 'feed' | 'kreise' }) => {
    const post = await api.editPost(postEntity);
    return { post, type };
  },
);

export const createSocialMediaPost = createAsyncThunk(
  'news/createSocialMediaPost',
  async ({ kreiseId, value, media }: { kreiseId: string; value: string; media: any }) => {
    return await api.createSocialMediaPost(kreiseId, value, media);
  },
);

export const getKreiseToPost = createAsyncThunk('news/getKreiseToPost', async () => {
  return await api.getKreiseToPost();
});

type TVote = {
  postId: string;
  optionId: string;
};

export const surveyAddVote = createAsyncThunk('news/surveyAddVote', async ({ postId, optionId }: TVote) =>
  api.surveyAddVote(postId, optionId),
);

export const surveyRemoveVote = createAsyncThunk('news/surveyRemoveVote', async ({ postId, optionId }: TVote) =>
  api.surveyRemoveVote(postId, optionId),
);

export const surveyGetVotes = createAsyncThunk(
  'news/surveyGetVotes',
  async ({ postId, optionId, page, limit }: TVote & { page: number; limit: number }) =>
    api.surveyGetVotes(postId, optionId, page, limit),
);

const socialMediaInitialState = {
  show: false,
  value: '',
  media: [] as { link: string; type: 'image' | 'video' }[],
  loading: false,
  editable: false,
  kreiseId: '',
  step: 0 as 0 | 1 | 2,
  originalText: '',
};

const postsInitialState = {
  news: [],
  loading: false,
  total: null as number,
  pinnedTotal: null as number,
  pinnedPageNumber: 0 as number,
  pinnedLoading: false,
  pageNumber: 0,
};

const adsInitialState = {
  loading: false,
  ads: [],
};

const initialState = {
  feed: postsInitialState,
  kreise: postsInitialState,
  ads: adsInitialState,
  addingNews: false,
  currentNews: null,
  editingPost: null as any,
  socialMediaPost: socialMediaInitialState,
  kreiseForNews: [] as { _id: string; name: string; isTicked: boolean }[],
};

const newsSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    clearNews: (state) => {
      state.feed = postsInitialState;
    },
    clearKreiseNews: (state) => {
      state.kreise = postsInitialState;
    },
    clearAds: (state) => {
      // state.adsList = [];
      state.ads = adsInitialState;
    },
    startEditing: (state, action: any) => {
      state.editingPost = action.payload;
    },
    cancelEditing: (state) => {
      state.editingPost = null;
    },
    setSocialMediaPost: (state, action: PayloadAction<Partial<typeof socialMediaInitialState>>) => {
      state.socialMediaPost = { ...state.socialMediaPost, ...action.payload };
    },
    setSocialMediaPostInitialState: (state) => {
      state.socialMediaPost = socialMediaInitialState;
    },
    modifyPost: (state, action: PayloadAction<{ type: 'kreise' | 'feed'; post: any; moveToTop?: boolean }>) => {
      const idx = state[action.payload.type].news.findIndex((el) => el?._id === action.payload.post?._id);
      state[action.payload.type].news[idx] = action.payload.post;

      if (action.payload.moveToTop)
        state[action.payload.type].news.unshift(state[action.payload.type].news.splice(idx, 1)[0]);
    },
  },
  extraReducers: (builder) => {
    // --> getNewsList
    builder.addCase(getNewsList.pending, (state) => {
      state.feed.loading = true;
    });
    builder.addCase(getNewsList.fulfilled, (state, action: any) => {
      state.feed.loading = false;
      state.feed.news = state.feed.news.concat(action.payload.post);
      state.feed.total = action.payload.total;
      state.feed.pageNumber = action.payload.page;
    });
    builder.addCase(getNewsList.rejected, (state) => {
      state.feed.loading = false;
    });
    //  getNewsList <--
    // --> getPinnedPosts
    builder.addCase(getPinnedPosts.pending, (state, action) => {
      const { type } = action.meta.arg;
      if (type === 'feed') state.feed.pinnedLoading = true;

      if (type === 'kreise') state.kreise.pinnedLoading = true;
    });
    builder.addCase(getPinnedPosts.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      const concatedNews = action.payload.post;

      if (type === 'feed') {
        state.feed.news = state.feed.news.concat(concatedNews);
        state.feed.pinnedLoading = false;
        state.feed.pinnedTotal = action.payload.total;
        state.feed.pinnedPageNumber = action.payload.page;
      }
      if (type === 'kreise') {
        state.kreise.news = state.kreise.news.concat(concatedNews);
        state.kreise.pinnedLoading = false;
        state.kreise.pinnedTotal = action.payload.total;
        state.kreise.pinnedPageNumber = action.payload.page;
      }
    });
    builder.addCase(getPinnedPosts.rejected, (state, action) => {
      const { type } = action.meta.arg;

      if (type === 'feed') state.feed.pinnedLoading = false;

      if (type === 'kreise') state.kreise.pinnedLoading = false;
    });
    //  getPinnedPosts <--

    // --> getNewListsById
    builder.addCase(getNewsListById.pending, (state) => {
      state.kreise.loading = true;
    });
    builder.addCase(getNewsListById.fulfilled, (state, action: any) => {
      state.kreise.loading = false;
      state.kreise.news = state.kreise.news.concat(action.payload.Data.post);
      state.kreise.total = action.payload.Data.total;
      state.kreise.pageNumber = action.payload.Data.page;
    });
    builder.addCase(getNewsListById.rejected, (state) => {
      state.kreise.loading = false;
    });
    //  getNewListsById <--

    // --> getNewsById
    builder.addCase(getNewsById.pending, (state) => {
      state.feed.loading = true;
    });
    builder.addCase(getNewsById.fulfilled, (state, action: any) => {
      state.feed.loading = false;
      state.currentNews = action.payload.Data;
    });
    builder.addCase(getNewsById.rejected, (state) => {
      state.feed.loading = false;
    });
    //  getNewsById <--

    // --> deleteNews
    builder.addCase(deleteNews.pending, (state) => {
      state.feed.loading = true;
      state.kreise.loading = true;
    });
    builder.addCase(deleteNews.fulfilled, (state, action: any) => {
      const newsArr = state.feed.news.filter((news: any) => news?._id !== action.meta.arg.postId);
      const kreiseNews = state?.kreise.news?.filter((news: any) => news?._id !== action.meta.arg.postId);
      state.feed.news = newsArr;
      state.kreise.news = kreiseNews;
      state.feed.loading = false;
      state.kreise.loading = false;
    });
    builder.addCase(deleteNews.rejected, (state) => {
      state.feed.loading = false;
      state.kreise.loading = false;
    });
    //  deleteNews <--

    // --> getAllAds
    builder.addCase(getAllAds.pending, (state) => {
      state.ads.loading = true;
    });
    builder.addCase(getAllAds.fulfilled, (state, action: any) => {
      const sortedAds = action.payload?.Data?.sort((a, b) => 0.5 - Math.random());

      state.ads.loading = false;
      state.ads.ads = sortedAds;
    });
    builder.addCase(getAllAds.rejected, (state) => {
      state.ads.loading = false;
    });
    //  getAllAds <--

    // --> addNews
    builder.addCase(addNews.pending, (state) => {
      state.addingNews = true;
    });
    builder.addCase(addNews.fulfilled, (state, action: any) => {
      state.addingNews = false;

      if (action.meta.arg?.postEntity?.post_type === 'feed') {
        state.feed.news = [...action.payload.Data, ...state.feed.news];
      }

      if (typeof action.meta.arg?.postEntity?.post_type === 'object') {
        state.feed.news = [...action.payload.Data, ...state.feed.news];
        state.kreise.news = [...action.payload.Data, ...state.kreise.news];
      }

      const { content, images, videos } = action.meta.arg?.postEntity;
      if (action.payload.Data.some((el) => el.socialPosting) && (!!images?.length || !!videos?.length)) {
        state.socialMediaPost = {
          value: !content.length ? 'Ein:e Nutzer:in unser App hat ein neues Bild geteilt!' : '',
          media: [
            ...images.map((img: any) => ({
              link: img.url,
              type: 'image',
            })),
            ...videos.map((vid: any) => ({
              link: vid.url,
              type: 'video',
            })),
          ],
          show: true,
          loading: false,
          editable: false,
          kreiseId: action.payload.Data.filter((el) => el.socialPosting).map((el) => el.kreise),
          step: 1,
          originalText: content,
        };
      }
    });
    builder.addCase(addNews.rejected, (state) => {
      state.addingNews = false;
    });
    //  addNews <--

    // --> submitEditing
    builder.addCase(submitEditing.pending, (state, action) => {
      const { type } = action.meta.arg;
      if (type === 'feed') state.feed.loading = true;
      if (type === 'kreise') state.kreise.loading = true;
    });
    builder.addCase(submitEditing.fulfilled, (state, action) => {
      const { type } = action.payload;
      if (type === 'feed') state.feed.loading = false;
      if (type === 'kreise') state.kreise.loading = false;

      const idx = state[type].news.findIndex((post) => post?._id === action.payload.post._id);
      state[type][idx] = { ...state[type][idx], ...action.payload.post };
      state.editingPost = null;
    });
    builder.addCase(submitEditing.rejected, (state, action) => {
      const { type } = action.meta.arg;
      if (type === 'feed') state.feed.loading = false;
      if (type === 'kreise') state.kreise.loading = false;
    });
    //  submitEditing <--

    // --> createSocialMediaPost
    builder.addCase(createSocialMediaPost.pending, (state) => {
      state.feed.loading = true;
    });
    builder.addCase(createSocialMediaPost.fulfilled, (state) => {
      state.feed.loading = false;
      state.socialMediaPost = socialMediaInitialState;
    });
    builder.addCase(createSocialMediaPost.rejected, (state) => {
      state.feed.loading = false;
    });
    //  createSocialMediaPost <--

    // --> addVote
    builder.addCase(surveyAddVote.pending, (state) => {
      state.feed.loading = true;
    });
    builder.addCase(surveyAddVote.fulfilled, (state, action: any) => {
      state.feed.loading = false;

      const idx = state.feed.news.findIndex((post) => post?._id === action.payload._id);
      if (idx !== -1) state.feed.news[idx].survey = action.payload.survey;

      if (state.currentNews?._id === action.payload._id) {
        state.currentNews.survey = action.payload.survey;
      }

      const kreiseIdx = state.kreise.news.findIndex((post) => post?._id === action.payload._id);
      if (kreiseIdx !== -1) state.kreise.news[kreiseIdx].survey = action.payload.survey;
    });
    builder.addCase(surveyAddVote.rejected, (state) => {
      state.feed.loading = false;
    });
    //  addVote <--

    // --> removeVote
    builder.addCase(surveyRemoveVote.pending, (state) => {
      state.feed.loading = true;
    });
    builder.addCase(surveyRemoveVote.fulfilled, (state, action: any) => {
      state.feed.loading = false;
      const idx = state.feed.news.findIndex((post) => post?._id === action.payload._id);
      if (idx !== -1) state.feed.news[idx].survey = action.payload.survey;

      if (state.currentNews?._id === action.payload._id) {
        state.currentNews.survey = action.payload.survey;
      }

      const kreiseIdx = state.kreise.news.findIndex((post) => post?._id === action.payload._id);
      if (kreiseIdx !== -1) state.kreise.news[kreiseIdx].survey = action.payload.survey;
    });
    builder.addCase(surveyRemoveVote.rejected, (state) => {
      state.feed.loading = false;
    });
    //  removeVote <--

    // --> getKreiseToPost
    // builder.addCase(getKreiseToPost.pending, (state) => {}),
    // builder.addCase(getKreiseToPost.fulfilled, (state, action: PayloadAction<{data:string[]}>) => {
    // @ts-ignore
    builder.addCase(
      getKreiseToPost.fulfilled,
      (state, action: PayloadAction<{ data: { _id: string; name: string; isTicked: boolean }[] }>) => {
        state.kreiseForNews = action.payload.data;
      },
    );
    // builder.addCase(getKreiseToPost.rejected, (state) => {}),
    //  getKreiseToPost <--
  },
});

export const news = newsSlice.reducer;
export const {
  clearNews,
  clearAds,
  startEditing,
  cancelEditing,
  setSocialMediaPost,
  setSocialMediaPostInitialState,
  clearKreiseNews,
  modifyPost,
} = newsSlice.actions;
export const selectNews = (state: RootState) => state.news;
