import { RootState } from '../index';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as api from '../../api/search';

export type SearchRequestParams = {
  include?: boolean;
  limit?: number;
  filter: TSearchFilter;
  page: number;
  searchkey?: string;
  clear?: boolean;
};

export const searchRequest = createAsyncThunk(
  'search/searchRequest',
  async ({ include = false, limit = 30, filter, page, searchkey }: SearchRequestParams) => {
    try {
      const res = await api.searchRequest(include, limit, filter, page, searchkey);
      return res;
    } catch (error) {
      console.log('search request error', error);
    }
  },
);

export const searchFilteredRequest = createAsyncThunk(
  'search/searchFilteredRequest',
  async ({
    entity,
    limit = 30,
    filter,
    page,
    searchkey,
  }: {
    include?: boolean;
    limit?: number;
    filter: TSearchFilter;
    entity: string;
    page: number;
    searchkey?: string;
    clear?: boolean;
  }) => {
    try {
      const res = await api.searchFilteredUsers(filter, entity, limit, 'name', page, searchkey);
      return res;
    } catch (error) {
      console.log('search request error', error);
    }
  },
);

type TSearch = {
  isLoading: boolean;
  data: any[];
  count: number | null;
  searchKey: string;
  scrollPosY: number;
  filtered: {
    filter: string;
    searchKey: string;
    users: any[];
    isLoading: boolean;
    count: number | null;
  };
};

export type TSearchFilter =
  | 'name'
  | 'corpsid'
  | 'funktion'
  | 'stadt'
  | 'postcode'
  | 'land'
  | 'industry'
  | 'company'
  | 'beruf'
  | 'interessen'
  | 'studiengang'
  | 'hochschule'
  | 'activity';

const initialObj: TSearch = {
  isLoading: false,
  data: [],
  count: null,
  searchKey: '',
  scrollPosY: 0,
  filtered: {
    filter: '',
    searchKey: '',
    users: [],
    count: null,
    isLoading: false,
  },
};

const initialState = {
  searchQuery: '',
  filter: null,
  isBackActive: false,
  name: initialObj as TSearch,
  corpsid: initialObj as TSearch,
  funktion: initialObj as TSearch,
  stadt: initialObj as TSearch,
  postcode: initialObj as TSearch,
  land: initialObj as TSearch,
  industry: initialObj as TSearch,
  company: initialObj as TSearch,
  interessen: initialObj as TSearch,
  beruf: initialObj as TSearch,
  studiengang: initialObj as TSearch,
  hochschule: initialObj as TSearch,
  activity: initialObj as TSearch,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(searchRequest.pending, (state, action) => {
      state[action.meta.arg.filter].isLoading = true;
    });
    builder.addCase(searchRequest.fulfilled, (state, action) => {
      state[action.meta.arg.filter].isLoading = false;
      state[action.meta.arg.filter].data =
        !state[action.meta.arg.filter].data || action.meta.arg.clear
          ? action.payload.Data.list
          : state[action.meta.arg.filter].data.concat(action.payload.Data.list);
      state[action.meta.arg.filter].count = action.payload.Data.total;
    });
    builder.addCase(searchRequest.rejected, (state, action) => {
      state[action.meta.arg.filter].isLoading = false;
    });

    builder.addCase(searchFilteredRequest.pending, (state, action) => {
      state[action.meta.arg.filter].filtered.isLoading = true;
    });
    builder.addCase(searchFilteredRequest.fulfilled, (state, action) => {
      state[action.meta.arg.filter].filtered.isLoading = false;
      state[action.meta.arg.filter].filtered.users =
        !state[action.meta.arg.filter].filtered.users || action.meta.arg.clear
          ? action.payload.Data.list
          : state[action.meta.arg.filter].filtered.users.concat(action.payload.Data.list);
      state[action.meta.arg.filter].filtered.count = action.payload.Data.total;
    });
    builder.addCase(searchFilteredRequest.rejected, (state, action) => {
      state[action.meta.arg.filter].filtered.isLoading = false;
    });
  },
  reducers: {
    clearQuery: (state) => {
      state.searchQuery = '';
    },
    handleBackHandler: (state, action: PayloadAction<boolean>) => {
      state.isBackActive = action.payload;
    },
    setUsersFilter: (state, action: PayloadAction<{ value: string; key: TSearchFilter }>) => {
      state[action.payload.key].filtered.filter = action.payload.value;
    },
    setSearchQuery: (state, action: PayloadAction<{ value: string; key: TSearchFilter }>) => {
      state[action.payload.key].searchKey = action.payload.value;
    },
    setFilteredSearchQuery: (state, action: PayloadAction<{ value: string; key: TSearchFilter }>) => {
      state[action.payload.key].filtered.searchKey = action.payload.value;
    },
    setScrollPos: (state, action: PayloadAction<{ value: number; key: TSearchFilter }>) => {
      state[action.payload.key].scrollPosY = action.payload.value;
    },
  },
});

export const selectSearchState = (state: RootState) => state.media;

export const search = searchSlice.reducer;

export const searchActions = searchSlice.actions;
