import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withLanguage } from '../../languages/';
import SelectModal from '../../components/Shared/SelectModal';
import TouchableInput from '../../components/UI/TouchableInput';
import { updateUserRequest } from '../../store/actions/users';

interface Props {
  language: any;
  setlanguage: (langId: string) => void;
  offlineMode: boolean | undefined;
}

const LanguageSwitch: React.FC<Props> = ({ language, setlanguage, offlineMode }) => {
  const { switchLanguage } = language;
  const [value, setValue] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const user = useSelector((state: any) => state.users.user);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getLang() {
      if (user && user.language) {
        setValue(user.language);
      }
    }
    getLang();
  }, [user]);

  const onItemSelect = (label: string, name: string) => {
    let language = languageMap[label];

    if (language) {
      localStorage.setItem('userLang', language);
      const updatedUser = { ...user, language };

      setlanguage(language);
      !offlineMode && dispatch(updateUserRequest(updatedUser));
      setShowModal(false);

      setValue(language);
    }
  };

  const languageMap: Record<string, string> = {
    [switchLanguage.GER]: 'GER',
    [switchLanguage.ENG]: 'ENG',
    [switchLanguage.SP]: 'SP',
    [switchLanguage.FRA]: 'FRA',
    [switchLanguage.ITA]: 'ITA',
    [switchLanguage.CIN]: 'CIN',
    [switchLanguage.JAP]: 'JAP',
    [switchLanguage.KOR]: 'KOR',
    [switchLanguage.HNG]: 'HNG',
    [switchLanguage.RUS]: 'RUS',
    [switchLanguage.NLD]: 'NLD',
    // [switchLanguage.POL]: 'POL',
  };

  const modalData = useMemo(
    () =>
      Object.keys(languageMap).map((label) => ({
        label,
      })),
    [languageMap],
  );
  return (
    <React.Fragment>
      <TouchableInput
        onClick={() => setShowModal(true)}
        value={switchLanguage[value]}
        placeholder={switchLanguage.placeholder}
      />
      <SelectModal
        modalIsOpen={showModal}
        modalHandler={() => setShowModal(false)}
        modalTitle={switchLanguage.title}
        data={modalData}
        modalName="language"
        selectItemHandler={onItemSelect}
        currentLanguage={switchLanguage[value]}
      />
    </React.Fragment>
  );
};

export default withLanguage(LanguageSwitch);
