import { Box, ButtonBase } from '@material-ui/core';
import { CheckBox, CloseRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useHistory } from 'react-router';
import { getConfig } from '../../../config/config';
import { useLanguage } from '../../../languages/languageContext';
import { useAppDispatch, useAppSelector } from '../../../store';
import { imagePath } from '../../../utilities';
import { getMentoringRequests } from 'store/reducers/mentoring';
const defaultAvatar = require('../../../assets/default_avatar.png');
const searchedUser = require('../../../assets/searchedUser.png');
const { theme, config } = getConfig();
const useStyles = makeStyles({
  container: {
    display: 'flex',
    padding: '10px 15px',
    marginBottom: 10,
    backgroundColor: theme.BACKGROUND_SECONDARY,
    borderRadius: 20,
  },
  contentWrapper: {
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'center',
    flex: 1,
  },
  imageWrapper: {
    justifyContent: 'flex-end',
    width: 60,
    height: 60,
    marginRight: 10,
    borderRadius: 30,
    borderWidth: 2,
    overflow: 'hidden',
    borderColor: theme.ICON_BORDER,
    backgroundColor: theme.ICON_BACKGROUND,
  },
  heading: {
    alignSelf: 'center',
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  nameWrapper: {
    // flexDirection: 'row',
    flex: 1,
  },
  name: {
    fontSize: 16,
    flex: 1,
    width: '100%',
    color: theme.TEXT_PRIMARY,
  },
  corps: {
    fontSize: 12,
    // textAlign: 'left',
    maxWidth: 200,
    color: theme.TEXT_PRIMARY,
  },

  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

interface Props {
  user: any;
  acceptRequest: () => void;
  declineRequest: () => void;
  outgoing?: boolean;
  request: any;
  onClick?: () => void;
}

export const MenteeSearchItem: React.FC<Props> = ({
  user,
  acceptRequest,
  declineRequest,
  outgoing = false,
  request,
  onClick,
}) => {
  const styles = useStyles({});
  const { status, text: message, _id } = request;
  const {
    mentoring: { requests },
  } = useLanguage();
  const me = useAppSelector((state) => state.users.user);
  const history = useHistory();
  const isMentor = me.isMentor;
  const dispatch = useAppDispatch();

  const image = user?.photo ? imagePath(null, user.photo) : null;

  async function goToProfile(e) {
    if (e.target.id === 'declineRequest') return;
    const { payload } = await dispatch(getMentoringRequests());
    let neededRequest = {} as any;
    //@ts-ignore
    for (let type in payload) {
      if (type !== 'mentoringActive')
        neededRequest =
          payload[type]?.find((el) => {
            return el._id === request._id;
          }) || neededRequest;
    }
    if (
      neededRequest.status === REQUEST_STATUS.CANCELLED ||
      neededRequest.status === REQUEST_STATUS.DECLINED ||
      neededRequest.status === REQUEST_STATUS.FINISHED ||
      neededRequest.status === REQUEST_STATUS.FORCE_FINISH
    )
      return;
    if (onClick) {
      onClick();
      return;
    }

    history.push(`/mentoring/mentoring-request/${_id}`, {
      user,
      reason: 'accept',
      message,
      request: neededRequest,
    });
  }

  const RenderList = (el: string, idx: string) => (
    <span key={idx} className={styles.corps}>
      {el}
    </span>
  );

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }} onClick={(e) => goToProfile(e)}>
      <Box className={styles.container}>
        <Box className={styles.contentWrapper}>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Box style={{ alignItems: image ? undefined : 'center' }} className={styles.imageWrapper}>
              {image ? (
                <img src={image} className={styles.image} />
              ) : (
                <img src={defaultAvatar} style={{ width: 60, height: 60 }} />
              )}
            </Box>
            <Box className={styles.heading}>
              <Box className={styles.nameWrapper}>
                <span className={styles.name}>
                  {user.name} {user.lastname} {user.status ? `(${user.status})` : ''}
                </span>
              </Box>
            </Box>
          </Box>
          {status === 'accepted' ? (
            <ButtonBase
              onClick={declineRequest}
              style={{
                width: 90,
                alignSelf: 'center',
                backgroundColor: theme.ADD_BACKGROUND,
                borderWidth: 1,
                borderRadius: 10,
                border: `solid ${theme.ERROR_PRIMARY}50`,
              }}
              id="declineRequest"
            >
              <span style={{ textAlign: 'center' }} id="declineRequest">
                {requests?.cancelMentoring}
              </span>
            </ButtonBase>
          ) : (
            <Box style={{ alignSelf: 'center' }}>
              {status === 'sent' && (
                <span style={{ fontSize: 12, fontFamily: 'Roboto', textAlign: 'center' }}>Entscheidung: offen</span>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {status === 'accepted' && !isMentor && (
        <span style={{ textAlign: 'center', fontSize: 10, fontFamily: 'Roboto' }}>
          Klicke auf den Namen des Mentors, um eine Erinnerung zu senden oder die Anfrage zurückzuziehen
        </span>
      )}
    </Box>
  );
};

const REQUEST_STATUS = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  SENT: 'sent',
  FINISHED: 'finished',
  FORCE_FINISH: 'force_finish',
  CANCELLED: 'cancelled',
  SEEN: 'seen', // set isInformed to true
};
