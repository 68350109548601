import React, { FC, ReactNode, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';

import imagePlaceholder from '../../assets/imagePlaceholder.png';
import { ProgressLoader } from 'components/UI/ProgressLoader';
import { IonIcon } from 'components/UI/IonIcon';
import { Button } from 'components/shadcn/button';
import { useAppDispatch, useAppSelector } from '../../store';
import { useLanguage } from '../../languages/languageContext';

import {
  uploadDocument,
  uploadVideo,
  uploadImage,
  selectMediaState,
  mediaActions,
} from '../../store/reducers/mediaSlice';
import FilesLoader from 'components/Shared/FillesLoader';
import { showResponse } from 'store/actions/response';
import PDFToJPGConverter from 'utilities/PDFToJPGConverter';
import { fileType } from 'store/reducers/attachments';

const useStyles = makeStyles({
  container: {
    marginBottom: '15px',
  },
  deleteButton: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 20,
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(128, 128, 128, 0.5)',
    borderRadius: '0.375rem',
    cursor: 'pointer',
    backdropFilter: 'blur(4px)',
    transition: 'background-color 0.2s, transform 0.1s',
    '&:hover': {
      backgroundColor: 'rgba(128, 128, 128, 0.7)',
      transform: 'scale(1.05)',
    },
  },
  backdrop: {
    width: '100%',
    height: '10rem',
    borderRadius: 20,
    borderWidth: 1,
    border: 'dashed',
    borderColor: 'gray',
    backgroundColor: `#e0e0e030`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  dragover: {
    backgroundColor: `#e0e0e050`,
  },
  image: {
    height: '8rem',
    objectFit: 'cover',
  },
  placeholder: {
    mixBlendMode: 'multiply',
    zIndex: -1,
  },
  buttonLabel: {
    cursor: 'pointer',
  },
  errorText: {
    minHeight: '1rem',
    marginLeft: '0.75rem',
    textAlign: 'left',
    fontSize: '0.75rem',
    color: 'red',
  },
});

export const UploadMedia: FC<{
  receiver?: any;
  slideToBottom?: boolean;
  viewType?: 'default' | 'button' | 'dropzone' | 'singleImageInput';
  addPictureDisabled?: boolean;
  addPDFDisabled?: boolean;
  addVideoDisabled?: boolean;
  allFormats?: boolean;
  isPrivate?: boolean;
  additionalBtn?: ReactNode[];
  multiple?: boolean;
  text?: string;
  addLabel?: string;
  editLabel?: string;
  editImage?: string;
}> = ({
  receiver,
  slideToBottom = false,
  viewType = 'default',
  addPictureDisabled = false,
  addPDFDisabled = false,
  addVideoDisabled = false,
  allFormats = false,
  isPrivate = false,
  additionalBtn,
  multiple = true,
  text,
  addLabel = 'Add Image',
  editLabel = 'Edit Image',
  editImage,
}) => {
  const dispatch = useAppDispatch();
  const { responseHandler } = useLanguage();
  const [showLoader, setShowLoader] = useState(false);
  const [pdfs, setPdfs] = useState([]);
  const { documents, videos, images } = useAppSelector(selectMediaState);
  const styles = useStyles({});
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const allAttachment = [...(documents || []), ...(videos || []), ...(images || [])];
    const isAttachmentLoading = allAttachment.every((attachment: fileType) => attachment?.size);
    dispatch(mediaActions.setLoadingAllAttachments(isAttachmentLoading));
  }, [documents, images, videos]);

  const onDropFiles = (files: any) => {
    setShowLoader(false);
    files.forEach((file) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onloadend = async () => {
        if (file.size > 2048 * 1e6) {
          dispatch(showResponse({ message: responseHandler.maximalVideoSize }));
          return;
        }
        if (file.type.includes('video')) {
          dispatch(uploadVideo({ file, receiverId: receiver?._id, isPrivate }));
          return;
        }
        if (file.type.includes('image')) {
          dispatch(uploadImage({ file, receiverId: receiver?._id, isPrivate }));
          return;
        }
        if (file.type === 'application/pdf') {
          const formdata = new FormData();
          formdata.append('document', file);
          const payload: Partial<fileType> = {
            size: file.size,
            mimetype: file.type,
            fileName: file.name,
            renderUrl: URL.createObjectURL(file),
            formdata,
          };
          setPdfs((c) => [...c, payload]);
          dispatch(mediaActions.setLoadingAllAttachments(true));
          return;
        }

        const formdata = new FormData();
        formdata.append('document', file);
        dispatch(uploadDocument({ formdata, receiverId: receiver?._id, isPrivate }));
      };
    });
  };

  if (viewType === 'singleImageInput') {
    const formats = ['image/jpeg', 'image/png'];

    const deleteAttachment = () => {
      dispatch(mediaActions.deleteAttachment({ type: 'images', id: 0 }));
    };

    const onDropSingleImage = (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (formats.includes(file.type)) {
          deleteAttachment();
          dispatch(uploadImage({ file }));
          setError(null);
        } else {
          // todo: add text to languages
          setError('Unsupported file format');
        }
      }
    };

    const currentImage = images[0]?.renderUrl || images[0]?.url || editImage || imagePlaceholder;

    return (
      <>
        <Dropzone accept={formats} onDrop={onDropSingleImage} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <div>
              <div className={styles.container}>
                <input id="file" type="file" {...getInputProps()} />
                <Button>
                  <label htmlFor="file" className={styles.buttonLabel}>
                    {images[0] || editImage ? editLabel : addLabel}
                  </label>
                </Button>
              </div>
              <div
                {...getRootProps({
                  onClick: (e) => {
                    if ((e.target as HTMLElement).closest(`.${styles.deleteButton}`)) {
                      e.preventDefault();
                    }
                  },
                })}
                className={`${styles.backdrop} ${error ? styles.dragover : ''}`}
              >
                <div>
                  {(images[0] || editImage) && (
                    <div
                      className={styles.deleteButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteAttachment();
                      }}
                    >
                      <IonIcon name="trash" color="red" />
                    </div>
                  )}
                  <img
                    className={`${styles.image} ${!images[0] && !editImage && styles.placeholder}`}
                    src={currentImage}
                    // todo: add text to languages
                    alt="Selected or placeholder"
                  />
                  <ProgressLoader loading={!!images[0]?.progress} progress={images[0]?.progress} />
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        <div className={styles.errorText}>{error}</div>
      </>
    );
  }

  return (
    <>
      <FilesLoader
        onDrop={onDropFiles}
        showLoader={showLoader}
        loaderHandler={() => setShowLoader(!showLoader)}
        slideToBottom={slideToBottom}
        viewType={viewType}
        addPictureDisabled={addPictureDisabled}
        addPDFDisabled={addPDFDisabled}
        addVideoDisabled={addVideoDisabled}
        allFormats={allFormats}
        additionalBtn={additionalBtn}
        multiple={multiple}
        text={text}
      />
      {pdfs.map((pdf) => (
        <PDFToJPGConverter
          key={pdf.fileName}
          pdf={pdf}
          setPdfs={setPdfs}
          isPrivate={isPrivate}
          receiverId={receiver?._id}
        />
      ))}
    </>
  );
};
