import { getConfig } from 'config/config';
import { CACHE_EXPIRY_KEY, CACHE_KEY, CACHE_TTL, TLD_URL } from 'constants/local-storage';

const { config } = getConfig();

export const patterns: { [key: string]: RegExp } = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  characters: /^.{6,}$/,
  required: /^.{1,}$/,
  phone: /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/,
  password:
    config.APP_NAME === 'atlas'
      ? /// old validation regex
        // ? /^(?=.*[A-Za-zäöüÄÖÜß])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\])[A-Za-zäöüÄÖÜß\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{12,}$/
        // : /^(?=.*[A-Za-zäöüÄÖÜß])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\])[A-Za-zäöüÄÖÜß\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{8,}$/,
        /^(?=.*[A-Za-zäöüÄÖÜß])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-zäöüÄÖÜß\d])[\s\S]{12,}$/
      : /^(?=.*[A-Za-zäöüÄÖÜß])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-zäöüÄÖÜß\d])[\s\S]{8,}$/,
  link: /^(|https?:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)$/,
};

export const validateInput = (value: any, pattern: any): boolean => {
  if (pattern.test(value)) return true;
  return false;
};

export const isValidUrl = (url: string): boolean => {
  const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9.-]+(?:\.[a-zA-Z]{2,})+)(?:\/\S*)?$/;
  return urlRegex.test(url);
};

const fetchTlds = async (): Promise<string[]> => {
  const response = await fetch(TLD_URL);
  const data = await response.text();
  return data
    .split('\n')
    .slice(1)
    .slice(0, -1)
    .map((tld) => tld.toLowerCase());
};

const getTlds = async (): Promise<string[]> => {
  const cachedTlds = localStorage.getItem(CACHE_KEY);
  const expiry = localStorage.getItem(CACHE_EXPIRY_KEY);
  const now = Date.now();

  if (cachedTlds && expiry && now < parseInt(expiry)) {
    return JSON.parse(cachedTlds);
  }

  const tlds = await fetchTlds();
  localStorage.setItem(CACHE_KEY, JSON.stringify(tlds));
  localStorage.setItem(CACHE_EXPIRY_KEY, (now + CACHE_TTL).toString());

  return tlds;
};

let tldRegex: string | null = null;

export const extractUrls = async (text: string): Promise<string[]> => {
  if (!tldRegex) {
    const tlds = await getTlds();
    tldRegex = tlds.join('|');
  }

  const urlRegex = new RegExp(`(?:https?://)?([a-zA-Z0-9_-]+)\\.(${tldRegex})\\b(?:[/\\?][^\\s]*)?`, 'g');

  const matches = text.match(urlRegex);

  return matches ? matches : [];
};
