import { makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getConfig } from 'config/config';
import { globalSearchResponseType } from 'store/reducers/globalSearch';
import { ReactComponent as Icon } from '../../../../Drawer/assets/veranstaltungen.svg';
import { findOccurrences } from 'utilities/findOccurences';

type Props = {
  event?: globalSearchResponseType['events']['data'][0] | any;
  query: string;
};

export const EventItem: FC<Props> = (props) => {
  const { event, query } = props;

  const { _id, description, location, title } = event;
  const styles = useStyles({});
  const [occurences, setOccurences] = useState([] as string[]);

  useEffect(() => {
    if (query.length >= 3 && !!description?.length) {
      const result = findOccurrences(query, description);
      setOccurences(result);
    }
  }, []);

  return (
    <Link
      className={styles.container}
      to={{ pathname: `/calendar/${_id}?startDate=${event?.startDate}&endDate=${event?.endDate}` }}
    >
      <div className={styles.imageWrapper}>
        <div className={styles.scaledImaged}>
          <Icon />
        </div>
      </div>
      <div className={styles.infoWrapper}>
        <span className={styles.name}>{title}</span>
        {!!location?.length && <span className={styles.field}>Location: {location}</span>}
        {occurences?.map((el) => (
          <span className={styles.occurences}>...{el}...</span>
        ))}
      </div>
    </Link>
  );
};

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 80,
    backgroundColor: theme.BACKGROUND_SECONDARY,
    padding: 10,
    margin: 7,
    alignItems: 'center',
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  imageWrapper: {
    width: 60,
    height: 60,
    borderRadius: 60,
    background: theme.ICON_BACKGROUND,
    borderColor: theme.ICON_BORDER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid',
    color: theme.ICON_BORDER,
  },
  scaledImaged: {
    scale: 1.5,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 20px',
    alignItems: 'flex-start',
    flex: 1,
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
  },
  field: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 'normal',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
  },
  occurences: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    color: theme.TEXT_PRIMARY,
  },
});
