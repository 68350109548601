import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useLocation, useHistory } from 'react-router-dom';
import { getConfig } from 'config/config';
import { Button } from 'components/shadcn/button';
import { useLanguage } from 'languages/languageContext';
import { ErrorLabel } from 'components/UI/ErrorLabel';
import Logo from 'components/UI/Logo';
import BackButton from 'components/Shared/BackButton';
import { IonIcon } from 'components/UI/IonIcon';

const { config } = getConfig();

const Subscribe = () => {
  const { subscription } = useLanguage();
  const history = useHistory();
  const {
    state: {
      state: { clientSecret },
    },
  } = useLocation();

  const [messages, _setMessages] = useState('');

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(message);
  };

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return <></>;
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setMessage(submitError.message);
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${config.WEB_LINK}/subscription/prices`,
      },
    });

    if (error) {
      // show error and collect new card details.
      setMessage(error.message);
      return;
    }
    history.push('/account', { replace: false });
  };

  return (
    <div className="flex items-center justify-center h-[100vh] overflow-y-auto">
      <div className="flex flex-col self-center bg-white p-10 rounded-md">
        <button onClick={() => history.replace('/subscription/prices')} className="self-start">
          <IonIcon name="arrow-back" size={32} />
        </button>
        <div className="w-48 self-center">
          <Logo />
        </div>
        <form onSubmit={handleSubmit}>
          <PaymentElement onChange={() => setMessage('')} />

          <Button className="mt-4">{subscription.subscribe}</Button>
          <div className="w-72 mt-2">
            <ErrorLabel className="ml-0">{messages}</ErrorLabel>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Subscribe;
