import React, { useRef, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from 'store';
import { CommentType } from 'store/types/commentsTypes';
import {
  // getCommentsRequest,
  // clearComments,
  clearPostLikes,
  likePostRequest,
  unlikePostRequest,
} from 'store/actions/likes';

import { withConfig, withAuth, Theme } from 'config';
import PostGallery from 'components/Shared/PostGallery';
import PostSender from 'components/Shared/PostSender';
import Layout from 'components/UI/Layout';
import BackButton from 'components/Shared/BackButton';
import PostsToolbar from 'components/Shared/PostsToolbar';
import DocumentsGallery from 'components/Shared/DocumentsGallery';

import CommentsContent from './components/CommentsContent';
import Comment from './components/Comment';
import CommentsLikeButton from './components/CommentsLikeButton';
import PostLikesModal from './components/PostLikesModal';
import { NewsItemType } from 'store/types/newsTypes';
import { useLanguage } from 'languages/languageContext';
import { getNewsById } from 'store/reducers/news';
import { SurveyPostOld } from 'scenes/Post/components/SurveyPost_old';
import { clearComments, getComments } from 'store/reducers/commentsSlice';
import { UserType } from 'store/types/usersTypes';
import { HtmlPost } from 'scenes/Post/components/HtmlPost';
import SurveyPost from 'scenes/Post/components/SurveyPost';
import { AttachmentsGallery } from 'components/Shared/AttachmentsGallery';

const styles = createStyles({
  container: {
    position: 'relative',
    width: '100%',
    borderRadius: 10,
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Roboto',
    margin: 0,
    letterSpacing: '0.1',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  likeButtonWrapper: {
    padding: '10px 40px 30px',
  },
  postContent: {
    // width: '100%',
  },
  commentsWrapper: {
    width: '100%',
    padding: '10px 40px 20px',
    boxSizing: 'border-box',
  },
  commentsHeading: {
    width: '100%',
    textAlign: 'center',
    padding: '20px 0',
  },
  commentsTitle: {
    fontSize: 20,
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
  scrollWrapper: {
    // height: 400,
    width: '100%',
    overflow: 'hidden',
    paddingTop: 20,
    overflowX: 'hidden',
    borderRadius: 10,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
    border: 'none',
  },
  progressWrapper: {
    width: '100%',
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  senderWrapper: {
    padding: '0 20px',
  },
  '@media(max-width: 1024px)': {
    modalWrapper: {
      width: 650,
    },
  },
  '@media(max-width: 650px)': {
    modalWrapper: {
      width: '90%',
    },
    commentsWrapper: {
      padding: '10px 20px 20px',
    },
    likeButtonWrapper: {
      padding: '10px 20px 30px',
    },
    commentsHeading: {
      padding: '10px 0',
    },
    senderWrapper: {
      padding: '0 20px',
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  [key: string]: any;
  theme: Theme;
  onClose: () => void;
  post: {
    sender: object;
    time: any;
    kreise_name: string;
    comments: Array<string>;
    documents: Array<string>;
    images: Array<string>;
    post_type: string;
    updatedAt: any;
    lastCommentedAt: any;
    createdAt: any;
  };
}

const CommentsModal: React.FC<Props> = (props) => {
  const { post } = useLanguage();
  const dispatch = useAppDispatch();
  const scrollContainer = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { commentsIsLoading, comments, commentsCount } = useAppSelector((state) => state.comments);
  const {
    feed: { loading: newsIsLoading },
    currentNews,
  } = useAppSelector((state) => state.news);
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [news, setNews] = useState<NewsItemType | null>(null);

  const postId = props.history.location.pathname.replace('/posts-details/', '');

  const { isOwnerPosts = true } = props.history.location.state || {};

  const { classes, theme, history } = props;

  const userId = localStorage.getItem('userId');
  let likedPost = false;

  if (news && news.liked_users && userId) {
    likedPost = news.liked_users.includes(userId);
  }

  // INIT STATE
  const initState = () => {
    if (currentNews) {
      setNews({ ...currentNews });
    }
  };

  const toProfileHandler = (user: UserType) => {
    if (user._id) {
      props.history.push(`/profile-details/${user._id}`, { userId: user._id });
      dispatch(clearComments());
    }
  };

  // GET NEWS HANDLER
  const getNewsByIdHandler = () => {
    if (postId) dispatch(getNewsById({ postId }));
  };

  // COMMENTS HANDLERS
  const getCommentsHandler = () => {
    if (postId) dispatch(getComments({ postId, page: 1, limit: 10 }));
  };

  const getMoreCommentsHandler = () => {
    if (!commentsIsLoading) {
      setPageNumber((prev) => prev + 1);

      if (pageNumber > 1 && !commentsIsLoading) {
        dispatch(getComments({ postId, page: pageNumber, limit: 10 }));
      }
    }
  };

  // MODAL HANDLERS
  const onOpenLikesModal = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  };

  const onCloseLikesModal = () => {
    setShowModal(false);
    document.body.style.overflow = 'unset';
    dispatch(clearPostLikes());
  };

  const likePostHandler = () => {
    const userId = localStorage.getItem('userId');
    let likedPost = false;

    if (news && news.liked_users && userId) {
      likedPost = news.liked_users.includes(userId);
    }

    if (userId && !likedPost && news) {
      const updatedLikes = [...news.liked_users];
      updatedLikes.push(userId);

      setNews((prev) => {
        if (prev) {
          return { ...prev, liked_users: updatedLikes };
        } else {
          return prev;
        }
      });

      dispatch(likePostRequest(userId, news._id));
    } else if (userId && likedPost && news) {
      const updatedLikes = [...news.liked_users];

      const likeIndex = updatedLikes.indexOf(userId);
      const filteredLikes = updatedLikes.filter((like, i) => i !== likeIndex);

      setNews((prev) => {
        if (prev) {
          return {
            ...prev,
            liked_users: filteredLikes,
          };
        } else {
          return prev;
        }
      });

      dispatch(unlikePostRequest(userId, news._id));
    }
  };

  useEffect(() => {
    getCommentsHandler();
    getNewsByIdHandler();
    window.scrollTo(0, 0);

    return () => {
      dispatch(clearComments());
    };
  }, []);

  useEffect(() => {
    initState();
  }, [currentNews]);

  return (
    <React.Fragment>
      <Layout>
        {!newsIsLoading && news ? (
          <div className={classes.container}>
            <BackButton title={post.comment} onClick={() => history.goBack()} />
            <div ref={scrollContainer} className={classes.scrollWrapper} style={{ background: theme.BACKGROUND }}>
              <InfiniteScroll
                threshold={50}
                initialLoad={false}
                pageStart={0}
                loadMore={getMoreCommentsHandler}
                hasMore={!(comments.length >= commentsCount)}
                loader={
                  <div style={{ textAlign: 'center' }} key="9999">
                    <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
                  </div>
                }
                useWindow={true}
              >
                <div className={classes.postContent}>
                  <div className={classes.senderWrapper}>
                    <PostSender
                      sender={news.sender}
                      date={news.comments.length > 0 ? news.lastCommentedAt : news.createdAt}
                      kreiseName={isOwnerPosts ? news.kreise_name : null}
                      toProfileHandler={toProfileHandler}
                      kreiseId={news.post_type}
                      dateLabel={news.comments.length > 0 ? post.commented : post.posted}
                    />
                  </div>
                  {/* @ts-ignore */}
                  {news.isEmailPost ? (
                    <HtmlPost html={news.content} />
                  ) : (
                    <>
                      <CommentsContent post={news} />
                      {!!news?.questions?.[0]?.text && (
                        <div className="px-6">
                          <SurveyPost question={news.questions[0]} postId={news._id} showProgress={true} type="post" />
                        </div>
                      )}
                      {!!news?.survey?.length && (
                        <SurveyPostOld
                          postId={news._id}
                          content={news.surveyQuestion}
                          survey={news.survey}
                          multiAnswer={news.multiAnswer}
                          post={news}
                          type="post"
                        />
                      )}
                      <AttachmentsGallery images={news.images} videos={news.videos} documents={news.documents} />
                      {/* <PostGallery images={news.images} videos={news.videos} />
                      <div style={{ padding: '0 20px' }}>
                        {news.documents && news.documents.length > 0 && <DocumentsGallery files={news.documents} />}
                      </div> */}
                    </>
                  )}
                  <div className={classes.likeButtonWrapper}>
                    <CommentsLikeButton
                      likedUsers={news.liked_users}
                      likedPost={likedPost}
                      showLikesHandler={onOpenLikesModal}
                      likeHandler={likePostHandler}
                    />
                  </div>
                </div>
                <div className={classes.commentsHeading} style={{ background: theme.BACKGROUND_PRIMARY }}>
                  <span className={classes.commentsTitle} style={{ color: theme.TEXT_SECONDARY }}>
                    {news.comments.length > 0 ? `${post.comment} (${commentsCount})` : post.noComments}
                  </span>
                </div>
                <div className={classes.commentsWrapper}>
                  {comments.length > 0 &&
                    (comments as CommentType[]).map((comment) => (
                      <Comment
                        comment={comment}
                        commenter={comment.commenter}
                        key={comment._id}
                        date={comment.time}
                        content={comment.content}
                        likedUsers={comment.liked_users}
                        toProfileHandler={toProfileHandler}
                      />
                    ))}
                </div>
              </InfiniteScroll>
            </div>
            <div>
              {/* <CommentToolbar
                                    postId={postId}
                                /> */}
              <PostsToolbar postId={postId} toolbarType="post-comment" />
            </div>
          </div>
        ) : (
          <div className={classes.progressWrapper}>
            <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={45} />
          </div>
        )}
      </Layout>
      {showModal ? (
        <PostLikesModal showModal={showModal} modalTitle="Post Likes" onClose={onCloseLikesModal} post={news} />
      ) : null}
    </React.Fragment>
  );
};

export default withStyles(styles)(withRouter(withAuth(withConfig(CommentsModal))));
