import { getConfig } from 'config/config';
import React, { FC } from 'react';
import { IconType } from 'react-icons';
import * as IonIcons from 'react-icons/io5';

const { theme } = getConfig();

// modify old names
const mapIconName = (name: string): string => {
  return (
    'Io' +
    name
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('')
  );
};

interface IIonIconProps {
  name: string;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
  strokeWidth?: string | number;
}

export const IonIcon: FC<IIonIconProps> = ({ name, size = 16, color = theme.ACTIVE_INPUT, style = {} }) => {
  const mappedName = mapIconName(name);
  const IconComponent = (IonIcons as { [key: string]: IconType })[mappedName] as FC<IIonIconProps>;

  if (!IconComponent) return null;

  return <IconComponent size={size} color={color} style={style} name={name} />;
};
