import React, { useState, useEffect, memo, Fragment, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';

import { useAppSelector, useAppDispatch } from 'store';
import { showResponse } from 'store/actions/response';
import { withConfig, withAuth, Config } from 'config';
import Layout from 'components/UI/Layout';
import PostsToolbar from 'components/Shared/PostsToolbar';
import ScreenTitle from 'components/Shared/ScreenTitle';

import Post from '../Post';
import AdBlock from './components/AdBlock';
import { PostProps } from './model';
import { useLanguage } from 'languages/languageContext';
import { getAllAds, getNewsList, getPinnedPosts } from 'store/reducers/news';
import { ScrollToTopButton } from 'components/UI/ScrollToTop/ScrollToTopButton';
import PaginatedView from 'components/Shared/PaginatedView';

const styles = createStyles({
  container: {
    width: '100%',
    overflow: 'hidden',
    marginBottom: 40,
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
  },
  progressWrapper: {
    width: '100%',
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, PostProps {
  config: Config;
}

const Posts: React.FC<Props> = (props) => {
  const { screenTitles } = useLanguage();
  const dispatch = useAppDispatch();
  const { ads, feed } = useAppSelector((state) => state.news);
  const { total, news, loading, pageNumber, pinnedTotal, pinnedLoading, pinnedPageNumber } = feed;
  const isGuest = useAppSelector((state) => state.users.user?.isGuest);
  // // used to mark if guest already seen error modal, to stop paginagtion
  const { classes, theme } = props;

  useEffect(() => {
    if (!ads.ads?.length) {
      dispatch(getAllAds());
    }
  }, []);

  const handleScrollableEnd = () => {
    if (isGuest && news.length >= 15) {
      dispatch(showResponse({ message: 'Ältere Beiträge sind nur für Mitglieder einsehbar.' }));
    }
  };

  return (
    <React.Fragment>
      <Layout backgroundColor={news.length > 0 ? theme.BACKGROUND_SECONDARY : theme.BACKGROUND}>
        <ScreenTitle title={screenTitles.postsTitle} />
        <div className={classes.container}>
          <PaginatedView
            renderItem={(post, idx) => {
              if (ads?.ads?.length) {
                if (idx === 0 || (idx !== 0 && idx % 6 === 0)) {
                  let adIndex;
                  if (idx === 0) adIndex = 0;
                  else adIndex = (idx / 6) % ads.ads.length;

                  return (
                    <Fragment key={`ad_post_${idx}`}>
                      <Post isOwnerPosts={true} post={post} key={post?._id} />
                      <AdBlock key={`ad-${post._id}-${idx}`} item={ads.ads[adIndex]} />
                    </Fragment>
                  );
                }
              }
              return <Post isOwnerPosts={true} post={post} key={post?._id} />;
            }}
            getFirstBatch={() => dispatch(getPinnedPosts({ id: 'feed', type: 'feed', page: pinnedPageNumber + 1 }))}
            getMainBatch={() => dispatch(getNewsList({ page: pageNumber + 1, limit: 5 }))}
            onScrollableEnd={handleScrollableEnd}
            FBLoading={pinnedLoading}
            MBLoading={loading}
            MBTotal={total}
            FBTotal={pinnedTotal}
            items={news}
            key="news"
          />

          <div>
            <PostsToolbar toolbarType="post" />
          </div>
        </div>
      </Layout>
      <ScrollToTopButton />
    </React.Fragment>
  );
};

export default withStyles(styles)(withAuth(withConfig(memo(Posts))));
