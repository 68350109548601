import { API } from '../constants';
import { client } from './client';
import { authHeader, handleConnectionError, handleResponse } from './core';

// GET NEWS LIST
export const getNewsList = (
  page: number,
  limit = 5,
): Promise<{ limit: number; page: number; total: number; post: any[] }> =>
  client.get(`/v2/post/news/${page}/${limit}?showPinned=false`);

// GET NEWS LIST BY ID
export const getNewsListById = (kreiseId: string, page: number, limit = 5) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/post/${kreiseId}/${page}/${limit}?showPinned=false`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET NEWS BY ID
export const getNewsById = (postId: string) => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/postbyid/${postId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// ADD NEWS
export const addNews = (newsEntity: object) => {
  const options: any = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(newsEntity),
  };

  return fetch(`${API}/v4/post`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// DELETE NEWS
export const deleteNews = (postId: string) => {
  const options: any = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${API}/v2/post/${postId}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

export const generateSubject = (prompt: string, abortController: AbortController) =>
  client.post('/v2/post/subject', { prompt }, { signal: abortController.signal, timeout: 30000 });

export const generateSocialText = (originalText: string, abortController: AbortController) =>
  client.post('/v4/social-media/generate-text ', { originalText }, { signal: abortController.signal, timeout: 30000 });

export const editPost = (postEntity: any): Promise<{ _id: string } & any> =>
  client.put(`/v2/post/${postEntity._id}`, postEntity);

export const createSocialMediaPost = (
  kreise: string,
  caption: string,
  media: { link: string; type: 'image' | 'video' }[],
) => client.post('/v4/social-media/post', { kreise, caption, media });

export const surveyAddVote = (postId: string, optionId: string) =>
  client.post(`/v2/post/${postId}/add-vote/${optionId}`);

export const surveyRemoveVote = (postId: string, optionId: string) =>
  client.post(`/v2/post/${postId}/remove-vote/${optionId}`);

export const surveyGetVotes = (postId: string, optionId: string, page: number, limit = 5) =>
  client.get(`/v2/post/${postId}/list-vote/${optionId}?limit=${limit}&page=${page}`);

export const getKreiseToPost = () => client.get('/v2/kreise/newsfeed-list');

export const getPinnedPosts = (id: string, page: number): Promise<{ post: any[]; total: number; page: number }> =>
  // client.get(`/v2/post/${id}/pinned?limit=${limit}&page=${page}`);
  client.get(`/v2/post/${id}/pinned?page=${page}`);

export const pinPostReq = (id: string, payload: any) => client.post(`/v2/post/${id}/pin`, payload);

export const unpinPostReq = (id: string) => client.post(`/v2/post/${id}/unpin`);

export const addVote = (postId: string, questionId: string, optionId: string) =>
  client.post(`/v2/post/${postId}/add-vote-new/${questionId}/${optionId}`);

export const removeVote = (postId: string, optionId: string) =>
  client.post(`v2/post/${postId}/remove-vote-new/${optionId}`);

export const getVotes = (postId: string, optionId: string): Promise<any[]> =>
  client.get(`v2/post/${postId}/list-vote-new/${optionId}`);
