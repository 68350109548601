import { useLanguage } from './../languages/languageContext';
import TimeAgo from 'javascript-time-ago';

import de from 'javascript-time-ago/locale/de';
import en from 'javascript-time-ago/locale/en';
import fr from 'javascript-time-ago/locale/fr';
import hu from 'javascript-time-ago/locale/hu';
import it from 'javascript-time-ago/locale/it';
import es from 'javascript-time-ago/locale/es';
import zh from 'javascript-time-ago/locale/zh';
import ru from 'javascript-time-ago/locale/ru';
import ko from 'javascript-time-ago/locale/ko';
import ja from 'javascript-time-ago/locale/ja';
import nl from 'javascript-time-ago/locale/nl';

const values = {
  GER: 'de-DE',
  ENG: 'en-US',
  FRA: 'fr-FR',
  HNG: 'hu-HU',
  ITA: 'it-IT',
  JAP: 'ja-JA',
  CIN: 'zh-ZH',
  SP: 'es-ES',
  KOR: 'ko-KO',
  RUS: 'ru-RU',
  NLD: 'nl-NL',
};

export const timeAgo = (time: Date | string | number, future = false) => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { key } = useLanguage();

  const locales = { de, en, fr, hu, it, es, zh, ru, ko, ja, nl };
  Object.values(locales).forEach((locale) => TimeAgo.addLocale(locale));

  let parsedTime = new Date(time);
  if (!future && parsedTime > new Date()) {
    parsedTime = new Date();
  }

  const timeAgo = new TimeAgo(values[key]);

  return timeAgo.format(parsedTime);
};
