import { subscription } from 'api/subscriptions';
import { Button } from 'components/shadcn/button';
import ScreenTitle from 'components/Shared/ScreenTitle';
import OldButton from 'components/UI/Button';
import { IonIcon } from 'components/UI/IonIcon';
import Layout from 'components/UI/Layout';
import Loader from 'components/UI/Loader';
import { useQuery } from 'hooks/useQuery';
import { useLanguage } from 'languages/languageContext';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { hideResponse, showResponse } from 'store/actions/response';
import { getOwnerProfileRequest } from 'store/actions/users';
import { cn } from 'utilities/utils';

const Subscription = () => {
  const { subscription: lSubscription } = useLanguage();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const [prices, setPrices] = useState([] as TPrice[]);
  const [subscriptions, setSubscriptions] = useState([] as TSubscription[]);
  const [loading, setLoading] = useState(false);
  const [publishableKey, setPublishableKey] = useState('');
  const [plans, setPlans] = useState([] as TPlan[]);
  const [otherSub, setOtherSub] = useState({
    expireDate: '',
    isActive: false,
    purchasePlatform: null as 'ios' | 'android',
    plan: null as TPlan,
  });

  const searchQuery = useQuery();
  const showSuccessModal = searchQuery.get('redirect_status') === 'succeeded';

  const activeSubscriptions = subscriptions.filter((sub) => sub.status === 'active');
  const activeSubscriptionsIds = activeSubscriptions.map((sub) => sub.plan.id);
  const pricesToRender = prices.filter((price) => !activeSubscriptionsIds.includes(price.id) && price?.product?.active);

  const activeSubscriptionRootId = otherSub?.plan?.rootId || null;
  const activeSub = plans.find((plan) => plan.type === 'stripe' && plan.rootId === activeSubscriptionRootId);
  const activeSubId = moment(otherSub.expireDate).isAfter() ? activeSub?.id : null;

  const user = useAppSelector((state) => state.users.user);

  useEffect(() => {
    if (showSuccessModal) {
      dispatch(
        showResponse({ title: lSubscription.successfulPayment.title, message: lSubscription.successfulPayment.text }),
      );
      history.replace('/subscription/prices');
    }

    setLoading(true);

    const getConfig = async () => {
      const {
        prices,
        //@ts-ignore
        _doc: {
          stripe: { publishableKey },
          subscriptions: plans,
        },
      } = await subscription.config();
      setPlans(plans);
      setPublishableKey(publishableKey);
      setPrices(prices);
    };

    const checkCustomer = async () => {
      try {
        let { customer } = await subscription.customer();

        await getSubscriptions();

        if (!customer) await subscription.createCustomer(localStorage.getItem('email'));
      } catch (err) {
        console.log('error ~ checkCustomer', err);
      }
    };

    checkCustomer();
    getConfig();

    setLoading(false);
  }, []);

  useEffect(() => {
    if (activeSubscriptions.length > 0 && user?.isGuest) {
      setTimeout(() => {
        const userId = localStorage.getItem('userId');
        dispatch(getOwnerProfileRequest(userId));
      }, 2000);
    }
  }, [activeSubscriptions.length, user]);

  const getSubscriptions = async () => {
    setLoading(true);
    const {
      subscriptions: { data: subscriptions },
      ...otherData
    } = await subscription.subscriptions();

    setOtherSub(otherData);

    setSubscriptions(subscriptions);
    setLoading(false);
  };

  const createSubscription = async (priceId: string) => {
    const { subscriptionId, clientSecret } = await subscription.createSubscription(priceId);

    history.push('/subscription/subscribe', {
      state: {
        from: location,
        subscriptionId,
        clientSecret,
        publishableKey,
      },
      replace: false,
    });
  };

  const cancelSubscription = async (sub: string, redirectTo?: 'ios' | 'android' | 'stripe') => {
    dispatch(
      showResponse({
        title: lSubscription.cancel.title,
        message: lSubscription.cancel.text,
        options: [
          <OldButton
            label={lSubscription.cancel.cancel}
            onClick={async () => {
              if (redirectTo === 'ios') {
                window.open('https://apps.apple.com/account/subscriptions');
                dispatch(hideResponse());

                return;
              }
              if (redirectTo === 'android') {
                window.open('https://play.google.com/store/account/subscriptions');
                dispatch(hideResponse());

                return;
              }
              await subscription.cancelSubscription(sub);
              await getSubscriptions();
              dispatch(hideResponse());
            }}
          />,
        ],
      }),
    );
  };

  // const getMultiplatformSubscription = async () => {
  //   if (otherSub.plan) {
  //     const { rootId } = otherSub.plan;

  //   }
  // };

  return (
    <Layout>
      <ScreenTitle title={lSubscription.subscription} />
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="px-8 py-4 overflow-y-auto h-full max-h-screen min-h-0 pb-60">
          <div className="h-4" />
          <div className="flex flex-col gap-2">
            {pricesToRender?.map((price) => {
              const isActive = activeSubId === price.product.id;
              const plan = plans.find((plan) => plan.id === price.product.id);
              const description = plan?.description;
              return (
                <>
                  {isActive && (
                    <div className="flex flex-row items-center gap-2">
                      <IonIcon name="star" size={22} />
                      <h2 className="text-lg font-medium">{lSubscription.subscriptionActive}</h2>
                      <h2 className="text-sm/[30px]">
                        ({lSubscription.until} {moment(otherSub.expireDate).format('DD.MM.YYYY')})
                      </h2>
                    </div>
                  )}
                  <Card
                    {...price}
                    createSubscription={createSubscription}
                    key={price.id}
                    isActive={isActive}
                    description={description}
                  />
                  {isActive && (
                    <Button
                      className="bg-red-600 my-2"
                      onClick={() => cancelSubscription(activeSubId, otherSub.purchasePlatform)}
                    >
                      {lSubscription.cancel.cancel}
                    </Button>
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
    </Layout>
  );
};

const Card = ({
  product,
  id,
  unit_amount,
  currency,
  isActive,
  recurring,
  createSubscription,
  description,
}: TPrice & { isActive?: boolean; description: string; createSubscription: (priceId: string) => Promise<void> }) => {
  const { subscription } = useLanguage();
  return (
    <div
      className={cn('border-2 rounded-md w-full p-3 hover:scale-[102%] duration-150', isActive && 'border-primary-btn')}
    >
      <div className="flex flex-row justify-between items-center pb-2">
        <h3 className="font-medium">{product.name}</h3>
        <h3 className="font-regular text-sm">
          {(unit_amount / 100).toFixed(2)} {currency?.toUpperCase()}/
          {(recurring.interval_count > 1 ? recurring.interval_count + ' ' : '') + subscription[recurring.interval]}
        </h3>
      </div>
      <span>{description}</span>
      {isActive ? (
        <Button disabled className="text-sm h-8 my-2 bg-gray-300">
          {subscription.subscriptionActive}
        </Button>
      ) : (
        <Button className="text-sm h-8 my-2" onClick={() => createSubscription(id)}>
          Get {product.name} version
        </Button>
      )}
      <ul className="flex flex-col gap-2 text-sm">
        {product.features.map(({ name }) => (
          <li className="gap-2 flex items-center">
            <IonIcon name="checkmark" size={16} />
            {name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Subscription;

export interface TPrice {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: null;
  livemode: boolean;
  lookup_key: null;
  metadata: Metadata;
  nickname: null;
  product: TProduct;
  recurring: Recurring;
  tax_behavior: string;
  tiers_mode: null;
  transform_quantity: null;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

interface Recurring {
  aggregate_usage: null;
  interval: string;
  interval_count: number;
  meter: null;
  trial_period_days: null;
  usage_type: string;
}

interface Metadata {}

interface TProduct {
  id: string;
  object: string;
  active: boolean;
  attributes: any[];
  created: number;
  default_price: string;
  description: null;
  features: any[];
  images: any[];
  livemode: boolean;
  marketing_features: any[];
  metadata: Metadata;
  name: string;
  package_dimensions: null;
  shippable: null;
  statement_descriptor: null;
  tax_code: null;
  type: string;
  unit_label: null;
  updated: number;
  url: null;
}

interface Metadata {}

export interface TSubscription {
  id: string;
  object: string;
  application: null;
  application_fee_percent: null;
  automatic_tax: Automatictax;
  billing_cycle_anchor: number;
  billing_cycle_anchor_config: null;
  billing_thresholds: null;
  cancel_at: null;
  cancel_at_period_end: boolean;
  canceled_at: null;
  cancellation_details: Cancellationdetails;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  days_until_due: null;
  default_payment_method: null;
  default_source: null;
  default_tax_rates: any[];
  description: null;
  discount: null;
  discounts: any[];
  ended_at: null;
  invoice_settings: Invoicesettings;
  items: Items;
  latest_invoice: string;
  livemode: boolean;
  metadata: Metadata;
  next_pending_invoice_item_invoice: null;
  on_behalf_of: null;
  pause_collection: null;
  payment_settings: Paymentsettings;
  pending_invoice_item_interval: null;
  pending_setup_intent: null;
  pending_update: null;
  plan: Plan;
  quantity: number;
  schedule: null;
  start_date: number;
  status: string;
  test_clock: null;
  transfer_data: null;
  trial_end: null;
  trial_settings: Trialsettings;
  trial_start: null;
}

interface Trialsettings {
  end_behavior: Endbehavior;
}

interface Endbehavior {
  missing_payment_method: string;
}

interface Paymentsettings {
  payment_method_options: null;
  payment_method_types: null;
  save_default_payment_method: string;
}

interface Items {
  object: string;
  data: Datum[];
  has_more: boolean;
  total_count: number;
  url: string;
}

interface Datum {
  id: string;
  object: string;
  billing_thresholds: null;
  created: number;
  discounts: any[];
  metadata: Metadata;
  plan: Plan;
  price: Price;
  quantity: number;
  subscription: string;
  tax_rates: any[];
}

interface Price {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: null;
  livemode: boolean;
  lookup_key: null;
  metadata: Metadata;
  nickname: null;
  product: string;
  recurring: Recurring;
  tax_behavior: string;
  tiers_mode: null;
  transform_quantity: null;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

interface Recurring {
  aggregate_usage: null;
  interval: string;
  interval_count: number;
  meter: null;
  trial_period_days: null;
  usage_type: string;
}

interface Plan {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage: null;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: Metadata;
  meter: null;
  nickname: null;
  product: TProduct;
  tiers_mode: null;
  transform_usage: null;
  trial_period_days: null;
  usage_type: string;
}

interface Metadata {}

interface Invoicesettings {
  account_tax_ids: null;
  issuer: Issuer;
}

interface Issuer {
  type: string;
}

interface Cancellationdetails {
  comment: null;
  feedback: null;
  reason: null;
}

interface Automatictax {
  disabled_reason: null;
  enabled: boolean;
  liability: null;
}

export type TPlan = {
  currency: string;
  id: string;
  rootId: string;
  period: string;
  price: string;
  title: string;
  type: 'ios' | 'android' | 'stripe';
  description: string;
};
