import { useState } from 'react';
import { useFormik } from 'formik';
import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';
import { FormInput } from 'components/UI/FormInput';
import { NewDatePicker } from 'components/shadcn/datepicker';
import SearchableModal from 'components/Shared/SearchableModal';
import { MultipleSearchableInput } from 'components/Shared/MultipleSearchableInput';
import { useAppDispatch, useAppSelector } from 'store';
import { useHistory, useLocation } from 'react-router';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getCorpsNames } from 'api/corps';
import { MultipleModalItem } from 'components/UI/MultipleModalItem';
import { InputButton } from 'components/UI/InputButton';
import { countries } from 'utilities';
import { cn } from 'utilities/utils';
import Logo from 'components/UI/Logo';
import { Label } from 'components/shadcn/label';
import Conditions from 'scenes/SignIn/components/Conditions';
import { Button } from 'components/shadcn/button';
import { useQuery as useQueryParams } from 'hooks/useQuery';
import { parseBoolean } from 'config/setConfig';
import { deleteOwnerFailure } from 'store/actions/auth';
import { signUpSchema } from 'utilities/schema';
import DefaultModal from 'components/Shared/DefaultModal';
import { registration } from 'store/reducers/auth';
import { showResponse } from 'store/actions/response';
import Loader from 'components/UI/Loader';
import { checkEmail } from 'api/auth';
import { ErrorLabel } from 'components/UI/ErrorLabel';
import { Checkbox } from 'components/shadcn/checkbox';
import { PhoneInput } from 'components/shadcn/phoneInput';
import { Value } from 'react-phone-number-input';
const { config } = getConfig();

const { theme } = getConfig();

const initialValues = {
  name: '',
  lastname: '',
  email: '',
  emailRepeat: '',
  mobilfunknummer: '',
  password: '',
  postcode: '',
  wohnort: '',
  country: 'Deutschland',
  corp: [],
  invitationCode: '',
  memberId: '',
  beruf: '',
  street: '',
  birthday: undefined as Date,
  privacyPolicy: false,
};

export function SignUpScreen() {
  const { signUpManual, profileProfession, profileDetails, drawer, searchModal, signUp, invalidInputs } = useLanguage();

  const dispatch = useAppDispatch();
  const location = useLocation<{ isGuest: boolean }>();
  const history = useHistory();

  const query = useQueryParams();
  const withCode = parseBoolean(query.get('withCode'));
  const isGuest = parseBoolean(query.get('isGuest'));

  const { authIsLoading } = useAppSelector((state) => state.auth);
  const {
    maximal_membership_count,
    sign_up_description,
    registration_agb_checkbox,
    show_member_id_title,
    check_member_id,
    member_id_title,
  } = useAppSelector((state) => state.common);

  const [state, setState] = useState({
    showCorpsModal: false,
    searchQuery: '',
    showCountriesModal: false,
    countriesQuery: '',
  });
  const [emailWrongModal, setEmailWrongModal] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const { data } = useQuery({
    queryKey: ['corpsList', state.searchQuery],
    queryFn: () => getCorpsNames(state.searchQuery, location.state?.isGuest),
    placeholderData: keepPreviousData,
  });

  /// remove this when data will be sorted
  const corpsList =
    data?.Data.sort((a, b) => {
      const nameA = a.corpsname.toUpperCase();
      const nameB = b.corpsname.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }) || [];

  const isSoroptimist = config.APP_NAME === 'soroptimist' || config.APP_NAME === 'limconnect';

  const { values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue, setTouched, isValid, dirty } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema({
        invalidInputs,
        isSoroptimist,
        withCode,
        registration_agb_checkbox,
        corpLength: corpsList?.length,
      }),
      validateOnBlur: true,
      validateOnMount: false,
      onSubmit: async (values) => {
        try {
          setLoading(true);
          const payload: typeof values & { isGuest: boolean } = {
            ...values,
            country: values.country,
            isGuest,
          };

          if (!check_member_id) {
            delete payload.memberId;
          }

          if (withCode) {
            delete payload.corp;
          } else {
            delete payload.invitationCode;
            payload.corp =
              corpsList?.length > 1 ? payload.corp.map((corp) => ({ corpsname: corp.name, _id: corp._id })) : corpsList;
          }

          delete payload.privacyPolicy;

          await dispatch(registration(payload))
            .unwrap()
            .then(() => setDisabledButton(false))
            .catch((err) => {
              if (!err.message) {
                return;
              }

              if (err.message.includes('E-Mail')) {
                setEmailWrongModal(true);
              } else {
                dispatch(showResponse(err));
              }
              setDisabledButton(false);
            });
        } catch (error) {
          setLoading(false);
          console.log('error', error);
        } finally {
          setLoading(false);
        }
      },
    });

  const closeEmailWrongModal = () => {
    deleteOwnerFailure('');
    setEmailWrongModal(false);
  };

  const goToLogin = () => {
    deleteOwnerFailure('');
    history.push('/sign-in', {
      email: values?.email,
    });
  };

  const handleBlurEmail = async (fieldName: string) => {
    try {
      handleBlur(fieldName);

      if (!values.email) {
        return;
      }

      const { user } = await checkEmail(values.email);

      if (user) {
        setEmailWrongModal(true);
      }
    } catch (err) {
      dispatch(showResponse(err));
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center my-16">
        <div className="w-[320px]">
          <Logo />
          <h1 className="text-center text-2xl text-txt mb-6">{signUpManual.title}</h1>
          {!!sign_up_description?.length && (
            <>
              <Label className="ml-3">{signUp.description}</Label>
              <div className="border rounded-xl px-3 py-1 bg-transparent mb-4 text-sm text-txt shadow-sm">
                <span>{sign_up_description}</span>
              </div>
            </>
          )}

          {show_member_id_title && (
            <FormInput
              name="memberId"
              placeholder={member_id_title}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.memberId}
              error={errors.memberId}
              touched={touched.memberId}
            />
          )}
          <FormInput
            name="name"
            type="name"
            placeholder={signUpManual.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            error={errors.name}
            touched={touched.name}
          />

          <FormInput
            name="lastname"
            type="name"
            placeholder={signUpManual.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lastname}
            error={errors.lastname}
            touched={touched.lastname}
          />

          <FormInput
            name="email"
            type="email"
            placeholder={signUpManual.emailPlaceholder}
            onBlur={() => handleBlurEmail('email')}
            onChange={handleChange}
            value={values.email}
            error={errors.email}
            touched={touched.email}
          />

          <FormInput
            name="emailRepeat"
            type="email"
            placeholder={signUpManual.repeatEmailPlaceholder}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.emailRepeat}
            error={errors.emailRepeat}
            touched={touched.emailRepeat}
          />

          <FormInput
            name="password"
            type="password"
            placeholder={signUpManual.passwordPlaceholder}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            error={errors.password}
            touched={touched.password}
          />

          {isSoroptimist && (
            <NewDatePicker
              name={'birthday'}
              placeholder={profileDetails.birthdayLabel}
              date={values.birthday}
              error={touched.birthday && (errors.birthday as string)}
              setDate={(e) => {
                setFieldValue('birthday', e);
              }}
            />
          )}

          {withCode ? (
            <FormInput
              name="invitationCode"
              placeholder={signUpManual.loginCode}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.invitationCode}
              error={errors.invitationCode}
              touched={touched.invitationCode}
            />
          ) : (
            corpsList?.length > 1 && (
              <InputButton
                onClick={() => {
                  setTouched({ corp: true });
                  setState((c) => ({ ...c, showCorpsModal: true }));
                }}
                placeholder={signUpManual.corps}
                touched={touched.corp as boolean}
                error={errors.corp as string}
              />
            )
          )}

          <div className={cn(values.corp.length ? 'mb-5' : '')}>
            {values.corp?.map((item) => (
              <MultipleModalItem
                key={item._id}
                item={item}
                deleteItemHandler={(value) =>
                  setFieldValue(
                    'corp',
                    values.corp.filter((el) => el._id !== value._id),
                  )
                }
              />
            ))}
          </div>

          <PhoneInput
            name="mobilfunknummer"
            type="tel"
            placeholder={signUpManual.mobilePlaceholder}
            onBlur={handleBlur}
            value={values.mobilfunknummer}
            defaultCountry="DE"
            error={errors.mobilfunknummer}
            touched={touched.mobilfunknummer}
            onChange={(phoneNumber) => setFieldValue('mobilfunknummer', phoneNumber)}
            international
          />

          {isSoroptimist && (
            <>
              <FormInput
                name="beruf"
                placeholder={profileProfession.itemNameLabel}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.beruf}
                error={errors.beruf}
                touched={touched.beruf}
              />

              <FormInput
                name="street"
                placeholder={profileDetails.street}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.street}
                error={errors.street}
                touched={touched.street}
              />
            </>
          )}

          <div className="flex flex-row justify-between">
            <div className="mr-1">
              <FormInput
                name="postcode"
                placeholder={signUpManual.postcode}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.postcode}
                error={errors.postcode}
                touched={touched.postcode}
              />
            </div>
            <div className="ml-1">
              <FormInput
                name="wohnort"
                placeholder={signUpManual.place}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.wohnort}
                error={errors.wohnort}
                touched={touched.wohnort}
              />
            </div>
          </div>
          <InputButton
            onClick={() => {
              setTouched({ country: true });
              setState((c) => ({ ...c, showCountriesModal: true }));
            }}
            placeholder={signUpManual.country}
            value={values.country}
            label={signUpManual.country}
            touched={touched.country as boolean}
            error={errors.country as string}
          />
          {registration_agb_checkbox && (
            <div className="flex flex-col justify-start">
              <div className="flex flex-row gap-1 mx-1">
                <Checkbox
                  checked={values.privacyPolicy}
                  onCheckedChange={() => {
                    setTouched({ privacyPolicy: true });
                    setFieldValue('privacyPolicy', !values.privacyPolicy);
                  }}
                />
                <Conditions type="signUp" />
              </div>
              <ErrorLabel>{touched.privacyPolicy && errors.privacyPolicy}</ErrorLabel>
            </div>
          )}
          <Button
            asChild
            className="mt-4 mb-10"
            onClick={() => {
              if (!disabledButton) {
                if (isValid) setDisabledButton(true);
                handleSubmit();
              }
            }}
            style={{ backgroundColor: dirty && isValid ? theme.BUTTON_PRIMARY : theme.ICON_BORDER }}
          >
            <span>{signUpManual.title}</span>
          </Button>
          <SearchableModal
            modalOpen={state.showCorpsModal}
            modalTitle={`Bitte auswählen`}
            onClick={() => setState((c) => ({ ...c, showCorpsModal: false }))}
            setValueHandler={() => setState((c) => ({ ...c, showCorpsModal: false }))}
            value={undefined}
          >
            <MultipleSearchableInput
              data={corpsList.map((el) => ({ _id: el._id, name: el.corpsname })) || []}
              onSelect={(value) => setFieldValue('corp', [...values.corp, value])}
              placeholder={drawer.search}
              onChange={(e) => setState((c) => ({ ...c, searchQuery: e.target.value }))}
              deleteItemHandler={(value) =>
                setFieldValue(
                  'corp',
                  values.corp.filter((el) => el._id !== value._id),
                )
              }
              values={values.corp}
              value={state.searchQuery}
              inputName="corp"
              disabled={values.corp.length >= maximal_membership_count}
            />
          </SearchableModal>

          <SearchableModal
            modalOpen={state.showCountriesModal}
            modalTitle={searchModal.landTitle}
            onClick={() => setState((c) => ({ ...c, showCountriesModal: false }))}
            setValueHandler={() => setState((c) => ({ ...c, showCountriesModal: false }))}
            value={values.country}
          >
            <MultipleSearchableInput
              data={countries}
              onSelect={(value) => setFieldValue('country', value)}
              placeholder={drawer.search}
              onChange={(e) => setState((c) => ({ ...c, countriesQuery: e.target.value }))}
              deleteItemHandler={() => setFieldValue('country', [])}
              values={[values.country]}
              value={state.countriesQuery}
              inputName="country"
            />
          </SearchableModal>
          <Conditions type="otherInfo" />
        </div>
      </div>
      <Loader className="fixed z-[10]" isLoading={loading} />

      <DefaultModal
        showModal={emailWrongModal}
        modalWidth={350}
        onClose={closeEmailWrongModal}
        modalTitle="Account vorhanden"
        message="Sie haben bereits einen Account unter dieser Mailadresse."
      >
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button asChild className="my-4" size="lg" onClick={goToLogin}>
            <span>Zum Login</span>
          </Button>
        </div>
      </DefaultModal>
    </>
  );
}
