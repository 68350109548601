import React, { FC, useEffect, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import PostSender from '../../components/Shared/PostSender';
import PostContent from '../Post/components/PostContent';
import PostToolbar from '../Post/components/PostToolbar';
import Button from '../../components/UI/Button';
import DefaultModal from '../../components/Shared/DefaultModal';
import EventItem from '../Calendar/components/EventItem';
import formatDate from '../../utilities/formatDate';
import { useLanguage } from 'languages/languageContext';
import { AttachmentsGallery } from 'components/Shared/AttachmentsGallery';
import { deleteNews, modifyPost, startEditing, submitEditing } from 'store/reducers/news';
import { mediaActions } from 'store/reducers/mediaSlice';
import { getConfig } from 'config/config';
import { CollapsedPost } from './components/CollapsedPost';
import { IonIcon } from 'components/UI/IonIcon';
import { showResponse } from 'store/actions/response';
import { UserType } from 'store/types/usersTypes';
import { SurveyPostOld } from './components/SurveyPost_old';
import { useAppDispatch, useAppSelector } from 'store';
import {
  DropdownMenuItem,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from 'components/shadcn/Dropdown';
import { DotsVerticalIcon, Pencil1Icon, PinBottomIcon, PinTopIcon, DrawingPinFilledIcon } from '@radix-ui/react-icons';
import moment from 'moment';
import { pinPostReq, unpinPostReq } from 'api/news';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { cn } from 'utilities/utils';
import { HtmlPost } from './components/HtmlPost';
import SurveyPost from './components/SurveyPost';
import LinkPreview from 'components/Shared/LinkPreview';
import { extractUrls } from 'utilities/validation';
import { extractHrefAttributes } from 'utilities/extractHref';

const { theme } = getConfig();
const useStyles = makeStyles({
  newsWrapper: {
    position: 'relative',
    width: '100%',
    borderRadius: 10,
    marginBottom: 10,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    border: 'solid 1px',
    borderColor: '#ccc',
    '@media(max-width: 650px)': {
      padding: '15px 20px',
    },
  },
  postSenderWrapper: {
    padding: '20px 20px 0',
  },
  postWrapper: {
    padding: '0 20px',
  },
  toolbarWrapper: {
    padding: '0 20px 20px 20px',
  },
});

const Post: FC<{ isOwnerPosts?: boolean; post: any; kreisePosts?: boolean }> = ({
  post,
  isOwnerPosts,
  kreisePosts,
}) => {
  const classes = useStyles({});
  const language = useLanguage();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.users);
  const { admin_can_delete_posts } = useAppSelector((state) => state.common);
  const customHistory = useCustomHistory();
  const [state, setState] = useState({
    showModal: false,
    collapsed: true,
  });
  const [loading, setLoading] = useState(false);

  const toProfileHandler = (user: UserType) => {
    // @ts-ignore
    customHistory.customPush(`/profile-details/${user._id}`, { userId: user._id, receiver: user });
  };

  const toDetailsHandler = (postId: string) => {
    // @ts-ignore
    customHistory.customPush(`/posts-details/${postId}`, {
      postId,
      isOwnerPosts,
    });
  };

  const modalHandler = () => {
    setState((c) => ({
      ...c,
      showModal: !c.showModal,
    }));
  };

  const onDeleteHandler = (postId: string) => {
    setState({ ...state, showModal: false });
    dispatch(deleteNews({ postId }));
  };

  const getPostDate = () => {
    let date, dateLabel;
    if (!post.editedAt && !post.lastCommentedAt) {
      date = post.createdAt;
      dateLabel = '';
    }
    if (post?.event?.comment_time) {
      date = post.event?.comment_time;
      dateLabel = language.post.commented;
    }
    if (post.lastCommentedAt && !post.editedAt) {
      date = post.lastCommentedAt;
      dateLabel = language.post.commented;
    }
    if (!post.lastCommentedAt && post.editedAt) {
      date = post.editedAt;
      dateLabel = language.post.editedAt;
    }
    if (post.editedAt && post.lastCommentedAt) {
      const isLastEdited = post.editedAt > post.lastCommentedAt;
      if (isLastEdited) {
        date = post.editedAt;
        dateLabel = language.post.editedAt;
      }
      if (!isLastEdited) {
        date = post.lastCommentedAt;
        dateLabel = language.post.commented;
      }
    }
    return { date, dateLabel };
  };

  const handleCollapse = () => setState({ ...state, collapsed: false });

  const handleShowToGuests = () => {
    const { showToGuests } = post;
    const { privacy } = language;
    const type = kreisePosts ? 'kreise' : 'feed';

    if (showToGuests === 'yes') {
      dispatch(submitEditing({ postEntity: { ...post, showToGuests: 'no' }, type }));
      dispatch(showResponse({ message: privacy.post.ifShownModal }));
    }
    if (showToGuests === 'no') {
      dispatch(submitEditing({ postEntity: { ...post, showToGuests: 'yes' }, type }));
      dispatch(showResponse({ message: privacy.post.ifHiddenModal }));
    }
  };

  const userId = localStorage.getItem('userId');

  const owner = post?.sender._id === userId;
  const isAdmin = user?.kreiseAdmin?.find(
    (kreise) => kreise?.kreise === post?.post_type || kreise?.kreise === post?.kreise,
  );

  const { date, dateLabel } = getPostDate();

  const permissions = {
    showMenu: isAdmin || owner,
    allowDelete: owner || (admin_can_delete_posts && isAdmin),
    allowPin: isAdmin,
    allowEdit: owner && !post.isEvent && !post.isEmailPost,
    allowChangeGuestVisibility: owner,
  };

  const PinnedPost = () =>
    post.pinToTop && (
      <DrawingPinFilledIcon
        color={theme.BACKGROUND}
        width={32}
        height={32}
        className={cn('rounded-full p-[3px] -rotate-45')}
        style={{ backgroundColor: theme.BUTTON_PRIMARY }}
      />
    );

  const pinPost = async (type: 'unpin' | 'once' | '3d' | '5d' | '1w' | '2w') => {
    const newsSource = kreisePosts ? 'kreise' : 'feed';
    try {
      setLoading(true);
      if (type === 'unpin') {
        await unpinPostReq(post._id);
        await dispatch(modifyPost({ post: { ...post, pinToTop: false }, type: newsSource }));
        return;
      }
      let payload = {};
      if (type === 'once') {
        payload = {
          value: 'single',
          type: 'custom',
        };
      }
      if (type !== 'once') {
        const date = moment().add(type[0], type[1] as 'd' | 'w');
        payload = {
          value: date,
          type: 'date',
        };
      }
      await pinPostReq(post._id, payload);
      const pinToTop = type !== 'once';

      await dispatch(modifyPost({ post: { ...post, pinToTop }, type: newsSource, moveToTop: true }));
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } finally {
      setLoading(false);
    }
  };

  const ThreeDots = () => (
    <div>
      {loading && (
        <div className="relative z-50 h-0 items-center self-center text-center">
          <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={32} />
        </div>
      )}
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="flex items-center justify-center rounded-full p-1 hover:bg-gray-100">
            <DotsVerticalIcon width={26} height={26} color={theme.ACTIVE_INPUT} />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mr-16 sm:mr-12 md:mr-14 lg:mr-20 w-56">
          <DropdownMenuGroup>
            {/* Edit post */}
            {permissions.allowEdit && (
              <DropdownMenuItem
                onClick={() => {
                  dispatch(startEditing(post));
                  dispatch(mediaActions.setEditingAttachments(post));
                }}
              >
                <Pencil1Icon style={{ color: '#2671D1', width: 18, height: 18 }} />
                <span className="ml-1">{language.post.editPostLabel}</span>
              </DropdownMenuItem>
            )}
            {/* Edit post */}

            {/* Delete post */}
            {permissions.allowDelete && (
              <DropdownMenuItem onClick={modalHandler}>
                <IonIcon name="close-outline" size={18} color={'#2671D1'} />
                <span className="ml-1">{language.post.deletePostLabel}</span>
              </DropdownMenuItem>
            )}
            {/* Delete post */}

            {/* Change guest visibility */}
            {permissions.allowChangeGuestVisibility && (
              <DropdownMenuItem onClick={handleShowToGuests}>
                <IonIcon
                  name={post.showToGuests === 'yes' ? 'eye-off-outline' : 'eye-outline'}
                  size={18}
                  color={'#2671D1'}
                />
                <span className="ml-1">
                  {post.showToGuests === 'yes' ? language.privacy.post.ifShown : language.privacy.post.ifHidden}
                </span>
              </DropdownMenuItem>
            )}
            {/* Change guest visibility */}
          </DropdownMenuGroup>

          {permissions.allowPin && (
            <>
              {(permissions.allowEdit || permissions.allowDelete || permissions.allowChangeGuestVisibility) && (
                <DropdownMenuSeparator />
              )}
              {post.pinToTop && (
                <DropdownMenuItem onClick={() => pinPost('unpin')}>
                  <PinBottomIcon width={18} height={18} color={'#2671D1'} />
                  <span className="ml-1">{language.post.actions.pin.unpin}</span>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem onClick={() => pinPost('once')}>
                <PinTopIcon width={18} height={18} color={'#2671D1'} />
                <span className="ml-1">{language.post.actions.pin.once}</span>
              </DropdownMenuItem>
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  <DrawingPinFilledIcon width={18} height={18} color={'#2671D1'} className="-rotate-45" />
                  <span className="ml-1">{language.post.actions.pin.label}</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem onClick={() => pinPost('3d')}>{language.post.actions.pin.d3}</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => pinPost('5d')}>{language.post.actions.pin.d5}</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => pinPost('1w')}>{language.post.actions.pin.w1}</DropdownMenuItem>
                    <DropdownMenuItem onClick={() => pinPost('2w')}>{language.post.actions.pin.w2}</DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );

  const [urlArr, setUrlArr] = useState<string[]>([]);

  useEffect(() => {
    const fetchUrls = async () => {
      if (!post.isDisabledLinkPreview) {
        if (post.isEmailPost) {
          const urls = extractHrefAttributes(post.content);
          setUrlArr(urls);
        } else {
          try {
            const urls = await extractUrls(post.content);
            setUrlArr(urls);
          } catch (error) {
            console.error('Error extracting URLs:', error);
            setUrlArr([]);
          }
        }
      }
    };

    fetchUrls();
  }, [post.isDisabledLinkPreview, post.isEmailPost, post.content]);

  let component = (
    <>
      {post && (
        <CollapsedPost
          type="text"
          handleCollapse={handleCollapse}
          collapsed={state.collapsed}
          id={post._id}
          customHeight={!post.isDisabledLinkPreview && 20}
        >
          <div className={cn(classes.postWrapper)}>
            {!post.isDisabledLinkPreview && <LinkPreview url={urlArr?.[0]} handleOpenLink={window.open} />}
            <PostContent news={post} />
          </div>
        </CollapsedPost>
      )}
      {!!post?.questions?.[0]?.text && (
        <div className="px-6">
          <SurveyPost question={post.questions[0]} postId={post._id} showProgress={true} type="post" />
        </div>
      )}
      {!!post?.survey?.length && (
        <SurveyPostOld
          postId={post._id}
          content={post.surveyQuestion}
          survey={post.survey}
          multiAnswer={post.multiAnswer}
          post={post}
          type="post"
        />
      )}
      <AttachmentsGallery documents={post.documents} videos={post.videos} images={post.images} />
      <div className={classes.toolbarWrapper}>
        <PostToolbar post={post} toDetailsHandler={toDetailsHandler} />
      </div>
    </>
  );

  if (post.isEmailPost) {
    component = (
      <>
        <CollapsedPost
          type="html"
          id={post._id}
          handleCollapse={handleCollapse}
          collapsed={state.collapsed}
          customHeight={!post.isDisabledLinkPreview && 20}
        >
          <div className={classes.postWrapper}>
            <HtmlPost html={post.content} />
          </div>
          <AttachmentsGallery documents={post.documents} videos={post.videos} images={post.images} />
        </CollapsedPost>
        <div className={classes.toolbarWrapper}>
          <PostToolbar post={post} toDetailsHandler={toDetailsHandler} />
        </div>
      </>
    );
  }

  if (post.isEvent) {
    const formattedDate = formatDate(post.event.startDate);
    component = (
      <>
        <div style={{ paddingTop: '1rem' }} />
        <div className={classes.toolbarWrapper}>
          <EventItem
            key={post.event._id}
            date={formattedDate}
            event={post.event}
            showMembersHandler={() => setState({ ...state, ...post.event, showMembers: true })}
          />
        </div>
      </>
    );
  }
  return (
    <React.Fragment>
      <div className={cn(loading ? 'visible h-full w-full rounded-[11px] bg-black  opacity-60' : '')}>
        <div
          className={cn(classes.newsWrapper, post.pinToTop && 'shadow-lg')}
          style={{ background: theme.BACKGROUND }}
          key={post._id}
        >
          <div className={classes.postSenderWrapper}></div>
          <PostSender
            sender={post.sender}
            date={post?.event?.comment_time || post?.lastCommentedAt || post.createdAt}
            kreiseName={isOwnerPosts ? post.kreise_name : null}
            toProfileHandler={toProfileHandler}
            kreiseId={post.post_type}
            pinToTop={post?.pinToTop}
            dateLabel={dateLabel}
          >
            <div className="flex flex-row gap-1">
              <PinnedPost />
              {permissions.showMenu && <ThreeDots />}
            </div>
          </PostSender>
          {component}
        </div>
      </div>
      <DefaultModal
        showModal={state.showModal}
        onClose={modalHandler}
        modalTitle={'Beitrag löschen?'}
        message={post.isEvent ? language.post.deleteEventPost : language.post.deletePost}
      >
        <Button label={language.btn.cancelBtn} background={theme.BUTTON_SECONDARY} onClick={modalHandler} />
        <Button label="OK" onClick={() => onDeleteHandler(post._id)} />
      </DefaultModal>
    </React.Fragment>
  );
};

export default Post;
