import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
// import { searchCorpsRequest, clearCorpsSearch, searchFilterRequest } from '../../../../store/actions';
import SearchBar from '../../../../components/Shared/SearchBar';
import SearchedItem from '../SearchedItems/SearchedItem';
import { ReactComponent as CorpsIcon } from '../../../../assets/corpsIcon.svg';
import { ReactComponent as BerufIcon } from '../../../../assets/berufIcon.svg';
import { ReactComponent as BrancheIcon } from '../../../../assets/brancheIcon.svg';
import { ReactComponent as FirmaIcon } from '../../../../assets/firmaIcon.svg';
import FunctionIcon from '../../../../assets/FunctionIcon';
import { ReactComponent as InteressenIcon } from '../../../../assets/heart.svg';
import { ReactComponent as LandIcon } from '../../../../assets/landIcon.svg';
import { ReactComponent as StadtIcon } from '../../../../assets/stadtIcon.svg';
import { ReactComponent as StudiumIcon } from '../../../../assets/studiumIcon.svg';
import EmptyList from '../../../../components/Shared/EmptyList';
import FiltersModal from '../FiltersModal';
import SelectModal from '../../../../components/Shared/SelectModal';
import { debounce } from '../../../../utilities';
import { getConfig } from 'config/config';
import { useAppDispatch, useAppSelector } from 'store';
import { TSearchFilter, searchActions, searchRequest } from 'store/reducers/searchSlice';
import { useHistory } from 'react-router';
import UserItem from '../SearchedItems/UserItem';

const { theme } = getConfig();

const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'relative',
    zIndex: 0,
    paddingTop: 100,
  },
  inputWrapper: {
    width: '100%',
    padding: '0 20px',
    boxSizing: 'border-box',
    zIndex: 10,
    position: 'sticky',
    paddingTop: 25,
    top: 130,

    '@media(max-width: 1024px)': {
      width: 'calc(75% - 72px)',
    },
    '@media(max-width: 650px)': {
      width: 'calc(100% - 20px)',
    },
  },
  content: {
    width: '100%',
    paddingTop: 60,
  },
  listWrapper: {
    boxSizing: 'border-box',
    width: '100%',
  },
  '@media(max-width: 650px)': {
    inputWrapper: {
      padding: '0 10px',
      paddingTop: 25,
    },
  },
});

interface Props {
  toFilteredScreen: ({ filter, key }: { filter: string; key: TSearchFilter }) => void;
}

const SearchScreen: FC<Props> = ({ toFilteredScreen }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  //@ts-ignore
  const selectedPage: TSearchFilter = history.location.pathname.replace('/suche/', '');
  const { isLoading, data, count, scrollPosY, searchKey } = useAppSelector((state) => state.search[selectedPage]) || {};
  const classes = useStyles({});
  const [state, setState] = useState({
    pageNumber: 2,
  });
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  const queryFilter = params.searchKey || '';

  const getUsersHandler = () => {
    dispatch(
      searchRequest({
        filter: selectedPage,
        include: false,
        page: 1,
        searchkey: searchKey || queryFilter,
      }),
    );
  };

  useEffect(() => {
    window.scrollTo(0, scrollPosY);
    if (queryFilter?.length && data?.length) {
      clearSearchHandler({ searchkey: queryFilter });
    }
    if (!data?.length) getUsersHandler();
  }, []);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setState({ ...state, pageNumber: 2 });
    dispatch(searchActions.setSearchQuery({ key: selectedPage, value }));
    onChangeHandlerDebounce(value);
  };

  const onChangeHandlerDebounce = useCallback(
    debounce((value: string) => {
      dispatch(
        searchRequest({
          filter: selectedPage,
          include: false,
          page: 1,
          searchkey: value,
          clear: true,
        }),
      );
    }, 500),
    [],
  );

  const clearSearchHandler = ({ searchkey = '' }: { searchkey?: string }) => {
    setState({
      ...state,
      pageNumber: 1,
    });

    dispatch(searchActions.setSearchQuery({ key: selectedPage, value: searchkey }));

    dispatch(
      searchRequest({
        filter: selectedPage,
        include: false,
        page: 1,
        clear: true,
        searchkey,
      }),
    );
  };

  const getMoreUsersHandler = () => {
    const { pageNumber } = state;

    if (!isLoading) {
      setState({
        ...state,
        pageNumber: state.pageNumber + 1,
      });

      if (count > data?.length && !isLoading) {
        dispatch(
          searchRequest({
            filter: selectedPage,
            include: false,
            page: pageNumber,
            searchkey: searchKey,
          }),
        );
      }
    }
  };

  const mappedItems = (
    <div className={classes.listWrapper}>
      {data?.length > 0 ? (
        data
          .filter((item) => item)
          .map((item, i) =>
            selectedPage === 'name' || selectedPage === 'postcode' ? (
              <UserItem user={item} key={i} />
            ) : (
              <SearchedItem
                key={i}
                title={item.corpsname || item.name}
                count={item.total || item.count || 0}
                photo={item?.photo}
                onClickItem={() => {
                  toFilteredScreen({ filter: item.corpsname ? item._id : item.name, key: selectedPage });
                }}
                iconComponent={icons[selectedPage]}
              />
            ),
          )
      ) : (
        <EmptyList />
      )}
    </div>
  );

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.inputWrapper} style={{ background: theme.BACKGROUND }}>
          <SearchBar query={searchKey} onChange={onChangeHandler} clearSearch={() => clearSearchHandler({})} />
        </div>
        <div className={classes.content} style={searchKey.length ? { paddingTop: 90 } : {}}>
          {' '}
          <InfiniteScroll
            threshold={50}
            initialLoad={false}
            pageStart={0}
            loadMore={getMoreUsersHandler}
            hasMore={!(data?.length >= count)}
            useWindow={true}
            loader={
              <div style={{ textAlign: 'center' }} key={52}>
                <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
              </div>
            }
          >
            {mappedItems}
          </InfiniteScroll>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchScreen;

const icons: { [key in TSearchFilter]: React.ReactElement } = {
  beruf: <BerufIcon />,
  company: <FirmaIcon />,
  corpsid: <CorpsIcon />,
  funktion: <FunctionIcon fill={theme.ICON_BORDER} />,
  hochschule: <StudiumIcon />,
  studiengang: <StudiumIcon />,
  stadt: <StadtIcon />,
  postcode: <></>,
  name: <></>,
  industry: <BrancheIcon />,
  activity: <BrancheIcon />,
  interessen: <InteressenIcon />,
  land: <LandIcon />,
};
