import React, { ReactNode } from 'react';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import { withConfig, Theme } from '../../config/';
import CustomDropzone from '../Shared/CustomDropzone';
import { ReactComponent as AddPicture } from '../../assets/addPicture.svg';
import { ReactComponent as AddPdf } from '../../assets/addPdf.svg';
import PaperClipIcon from 'assets/icons/PaperClipIcon';

const styles = createStyles({
  wrapper: {
    position: 'relative',
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
    zIndex: 100,
  },
  addButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  loaderWrapper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 40,
    height: 100,
    borderRadius: 20,
    bottom: 45,
    visibility: 'hidden',
    opacity: 0,
    transition: 'all 0.2s',
  },
  loaderVisible: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 40,
    height: 100,
    borderRadius: 20,
    bottom: 45,
    visibility: 'visible',
    opacity: 1,
    transition: 'all 0.2s',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  onDrop: () => {};
  showLoader: boolean;
  loaderHandler: () => {};
  addPictureDisabled: boolean;
  addPDFDisabled: boolean;
  addVideoDisabled?: boolean;
  slideToBottom?: boolean;
  viewType?: 'default' | 'button' | 'dropzone';
  allFormats?: boolean;
  additionalBtn?: ReactNode[];
  multiple?: boolean;
  text?: string;
}

const FilesReader: React.FC<Props> = ({
  classes,
  theme,
  onDrop,
  showLoader,
  loaderHandler,
  addPictureDisabled = false,
  addPDFDisabled = false,
  addVideoDisabled = false,
  slideToBottom = false,
  viewType = 'default',
  allFormats = false,
  additionalBtn,
  multiple,
  text,
}) => {
  const formats = [];
  const docFormats = ['application/pdf'];
  if (!addPictureDisabled) formats.push(...['image/jpeg', 'image/png']);
  if (!addVideoDisabled) formats.push(...['video/mp4', 'video/quicktime', 'video/webm']);

  if (viewType === 'dropzone')
    return (
      <div>
        {!addPictureDisabled && (
          <CustomDropzone
            viewType={viewType}
            formats={formats}
            icon={<AddPicture />}
            onDrop={onDrop}
            multiple={multiple}
          />
        )}
      </div>
    );

  if (viewType === 'default')
    return (
      <div className={classes.wrapper}>
        <ButtonBase
          onClick={loaderHandler}
          className={classes.addButton}
          style={{ background: theme.BACKGROUND_SECONDARY }}
        >
          <PaperClipIcon size={28} />
        </ButtonBase>
        <div
          className={showLoader ? classes.loaderVisible : classes.loaderWrapper}
          style={{
            background: theme.BACKGROUND_SECONDARY,
            bottom: showLoader && slideToBottom ? -100 : 45,
          }}
        >
          {(!addPictureDisabled || !addVideoDisabled) && (
            <CustomDropzone formats={formats} icon={<AddPicture />} onDrop={onDrop} multiple={multiple} />
          )}
          {!addPDFDisabled && (
            <CustomDropzone formats={docFormats} icon={<AddPdf />} onDrop={onDrop} multiple={multiple} />
          )}
          {additionalBtn}
        </div>
      </div>
    );

  if (viewType === 'button' && allFormats)
    return (
      <div className={classes.wrapper}>
        <CustomDropzone icon={<PaperClipIcon />} onDrop={onDrop} multiple={multiple} />
      </div>
    );

  if (viewType === 'button')
    return (
      <div>
        {(!addPictureDisabled || !addVideoDisabled) && (
          <CustomDropzone
            formats={formats}
            icon={text || <AddPicture />}
            onDrop={onDrop}
            viewType={viewType}
            multiple={multiple}
          />
        )}
        {!addPDFDisabled && (
          <CustomDropzone
            formats={docFormats}
            icon={<AddPdf />}
            onDrop={onDrop}
            viewType={viewType}
            multiple={multiple}
          />
        )}
      </div>
    );
};

export default withStyles(styles)(withConfig(FilesReader));
