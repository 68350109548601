import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Subscribe from './Subscribe';

const SubscriptionWrapper = () => {
  const {
    state: {
      state: { clientSecret, publishableKey },
    },
  } = useLocation();
  const stripePromise = loadStripe(publishableKey);

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <Subscribe />
    </Elements>
  );
};

export default SubscriptionWrapper;
