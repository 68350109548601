export function checkIfImageExists(url?: string, callback?: (exists: boolean) => void) {
  if (!url?.length) return callback(false);
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}
